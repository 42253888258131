import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import firstImage from "../images/IMG_0915-2.jpg";
import secondImage from "../images/IMG_0918-2.jpg"
import thirdImage from "../images/IMG_0924-2.jpg"

const Coaching = () => {
    const location = useLocation();
    
    useEffect(() => {
        const sectionState = location.state
        if (sectionState !== null) {
            const currentSection = sectionState.section
            if (currentSection === "free-consultation-coaching" ||
                currentSection === "wellbeing-coaching" || 
                currentSection === "strength-training-coaching" || 
                currentSection === "nutrition-coaching") {
                document.getElementById(currentSection).scrollIntoView()
            }
        }
    }, [])

    const [freeConsultationCollapsibles, setFreeConsultationCollapsibles] = useState([
        { key: "free30m", isActive: false }
    ])

    const [nutritionCollapsibles, setNutritionCollapsibles] = useState([
        { key: 'nutritionInitial', isActive: false },
        { key: 'nutritionFollowUp', isActive: false }
    ]);

    const [strengthTrainingCollapsibles, setStrengthTrainingCollapsibles] = useState([
        { key: "movementAssessment", isActive: false },
        { key: 'strengthTraining30m', isActive: false },
        { key: 'strengthTraining45m', isActive: false }
    ])

    const [wellbeingCollapsibles, setWellbeingCollapsibles] = useState([
        { key: "wellbeing30m", isActive: false },
        { key: "wellbeing45m", isActive: false }
    ])

    const handleConsultationButtonClick = (key) => {
        setFreeConsultationCollapsibles((prevCollapsibles) =>
            prevCollapsibles.map((collapsible) => {
                if (collapsible.key === key) {
                    return { ...collapsible, isActive: !collapsible.isActive };
                }
                return collapsible;
            })
        )
    }

    const handleNutritionButtonClick = (key) => {
        setNutritionCollapsibles((prevCollapsibles) =>
            prevCollapsibles.map((collapsible) => {
                if (collapsible.key === key) {
                    return { ...collapsible, isActive: !collapsible.isActive };
                }
                return collapsible;
            })
        )
    }

    const handleStrengthTrainingButtonClick = (key) => {
        setStrengthTrainingCollapsibles((prevCollapsibles) =>
            prevCollapsibles.map((collapsible) => {
                if (collapsible.key === key) {
                    return { ...collapsible, isActive: !collapsible.isActive };
                }
                return collapsible;
            })
        )
    }

    const handleWellbeingButtonClick = (key) => {
        setWellbeingCollapsibles((prevCollapsibles) =>
            prevCollapsibles.map((collapsible) => {
                if (collapsible.key === key) {
                    return { ...collapsible, isActive: !collapsible.isActive };
                }
                return collapsible;
            })
        )
    }

    const navigate = useNavigate();

    const navigateToBooking = (session) => {
        navigate("/coaching/booking", {
            state: {session: session}
        })
    }

    return (
        <div className="coaching">
            <div className="free-consultation coaching-section" id="free-consultation-coaching">
                <div className="card">
                    <div className="coaching-information">
                        <div className="coaching-content">
                            <h1>Complimentary Consultation</h1>
                            <div className="coaching-message">
                                <p>Book in for a complimentary consultation to find out how Jacqui can help you reach your goals.
                                    With Jacqui's 30+ years of knowledge and experience in health and wellness, she can help guide and support you to reach your nutrition and fitness goals so you can look and feel amazing.
                                </p>
                            </div>
                            {freeConsultationCollapsibles.map((collapsible) => (
                                <React.Fragment key={collapsible.key}>
                                    <button
                                        type="button"
                                        className={`coaching-collapsible ${collapsible.isActive ? 'active' : ''}`}
                                        onClick={() => handleConsultationButtonClick(collapsible.key)}
                                    >
                                        {collapsible.key === "free30m" ? (
                                            "Complimentary Initial Consultation (30 minutes)"
                                        ) : null}
                                    </button>
                                    <div
                                        className={`content ${collapsible.isActive ? 'active' : ''}`}
                                        style={{ maxHeight: collapsible.isActive ? '100%' : 0 }}
                                    >
                                        <p>
                                            {collapsible.key === "free30m" ? (
                                                "30 minute complimentary consultation, can be done in person or online."
                                            ) : null}
                                        </p>
                                        <button type="button" className="book-session" onClick={() => navigateToBooking(collapsible.key)}>Book Session</button>
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                    <div className="coaching-image">
                        <img src={firstImage} alt="Nutrition" />
                    </div>
                </div>
            </div>

            <div className="nutrition coaching-section" id="nutrition-coaching">
                <div className="card">
                    <div className="coaching-information">
                        <div className="coaching-content">
                            <h1>Nutrition</h1>
                            <div className="coaching-message">
                                <p>Do you want to feel confident without your clothes on?
                                    Do you want to slide into your jeans instead of squeezing into them?
                                    Do you want to lose weight and never find it again?
                                    With my many years of experience and knowledge I will guide and support you to reach your eating, food and nutrition goals so you can look and feel amazing.
                                </p>
                            </div>
                            {nutritionCollapsibles.map((collapsible) => (
                                <React.Fragment key={collapsible.key}>
                                    <button
                                        type="button"
                                        className={`coaching-collapsible ${collapsible.isActive ? 'active' : ''}`}
                                        onClick={() => handleNutritionButtonClick(collapsible.key)}
                                    >
                                        {collapsible.key === "nutritionInitial" ? (
                                            "Initial Nutrition Consultation (1 hour)"
                                        ) : collapsible.key === "nutritionFollowUp" ? (
                                            "Follow Up Nutrition Consultation (30 minutes)"
                                        ) : null}
                                    </button>
                                    <div
                                        className={`content ${collapsible.isActive ? 'active' : ''}`}
                                        style={{ maxHeight: collapsible.isActive ? '100%' : 0 }}
                                    >
                                        <p>
                                            {collapsible.key === "nutritionInitial" ? (
                                                "60 minute nutrition consultation, can be done in person or online."
                                            ) : collapsible.key === "nutritionFollowUp" ? (
                                                "30 minute follow up nutrition consultation, can be done in person or online."
                                            ) : null}
                                        </p>
                                        <p>
                                            {collapsible.key === "nutritionInitial" ? (
                                                "Investment: $140 NZD"
                                            ) : collapsible.key === "nutritionFollowUp" ? (
                                                "Investment: $75 NZD"
                                            ) : null}
                                        </p>
                                        <button type="button" className="book-session" onClick={() => navigateToBooking(collapsible.key)}>Book Session</button>
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                    <div className="coaching-image">
                        <img src={firstImage} alt="Nutrition" />
                    </div>
                </div>
            </div>

            <div className="strength-training coaching-section" id="strength-training-coaching">
                <div className="card">
                    <div className="coaching-information">
                        <div className="coaching-content">
                            <h1>Strength Training</h1>
                            <div className="coaching-message">
                                <p>Are you time poor?  Would you like to build muscle, improve your sleep and feel great without spending hours in the gym?
                                I will show you can train effectively with correct form so you can be in and out of the gym in 30 minutes.  
                                If you don't have access to a gym I can show you how you can build strength in the comfort of your home.
                                A strong body is a strong mind. As I say to most of my clients, you are paid for your brain so look after your body, move it and feed it well.
                                </p>
                            </div>
                            {strengthTrainingCollapsibles.map((collapsible) => (
                                <React.Fragment key={collapsible.key}>
                                    <button
                                        type="button"
                                        className={`coaching-collapsible ${collapsible.isActive ? 'active' : ''}`}
                                        onClick={() => handleStrengthTrainingButtonClick(collapsible.key)}
                                    >
                                        {collapsible.key === "movementAssessment" ? (
                                            "Movement Assessment (45 minutes)"
                                        ) : collapsible.key === "strengthTraining30m" ? (
                                            "Strength Training (30 minutes)"
                                        ) : collapsible.key === "strengthTraining45m" ? (
                                            "Strength Training (45 minutes)"
                                        ) : null}
                                    </button>
                                    <div
                                        className={`content ${collapsible.isActive ? 'active' : ''}`}
                                        style={{ maxHeight: collapsible.isActive ? '100%' : 0 }}
                                    >
                                        <p>
                                            {collapsible.key === "movementAssessment" ? (
                                                "45 minute movement assessment that involves body measurements (if you wish), comprehensive assessment and an individual exercise program created for you, can be done in person or online."
                                            ) : collapsible.key === "strengthTraining30m" ? (
                                                "30 minute strength training session, can be done in person or online - no equipment needed."
                                            ) : collapsible.key === "strengthTraining45m" ? (
                                                "45 minute strength training session, can be done in person or online - no equipment needed."
                                            ) : null}
                                        </p>
                                        <p>
                                            {collapsible.key === "movementAssessment" ? (
                                                "Investment: $120 NZD"
                                            ) : collapsible.key === "strengthTraining30m" ? (
                                                "Investment: $75 NZD"
                                            ) : collapsible.key === "strengthTraining45m" ? (
                                                "Investment: $95 NZD"
                                            ) : null}
                                        </p>
                                        <button type="button" className="book-session" onClick={() => navigateToBooking(collapsible.key)}>Book Session</button>
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                    <div className="coaching-image">
                        <img src={secondImage} alt="Strength Training" />
                    </div>
                </div>
            </div>

            <div className="wellbeing coaching-section" id="wellbeing-coaching">
                <div className="card">
                    <div className="coaching-information">
                        <div className="coaching-content">
                            <h1>Wellbeing</h1>
                            <div className="coaching-message">
                                <p>Choosing me as your exercise professional will help you take control of your goals and turn them into reality.
                                    As your exercise professional I will help you strive to be the best that you can be through my strong communication, balanced approach and expert advice.
                                    It doesn't matter where you are on your wellbeing journey, if you want to live a balanced life doing the things that you love then contact me today.
                                </p>
                            </div>
                            {wellbeingCollapsibles.map((collapsible) => (
                                <React.Fragment key={collapsible.key}>
                                    <button
                                        type="button"
                                        className={`coaching-collapsible ${collapsible.isActive ? 'active' : ''}`}
                                        onClick={() => handleWellbeingButtonClick(collapsible.key)}
                                    >
                                        {collapsible.key === "wellbeing30m" ? (
                                            "Wellbeing (30 minutes)"
                                        ) : collapsible.key === "wellbeing45m" ? (
                                            "Wellbeing (45 minutes)"
                                        ) : null}
                                    </button>
                                    <div
                                        className={`content ${collapsible.isActive ? 'active' : ''}`}
                                        style={{ maxHeight: collapsible.isActive ? '100%' : 0 }}
                                    >
                                        <p>
                                            {collapsible.key === "wellbeing30m" ? (
                                                "30 minute wellbeing session, can be done in person or online."
                                            ) : collapsible.key === "wellbeing45m" ? (
                                                "45 minute wellbeing session, can be done in person or online."
                                            ) : null}
                                        </p>
                                        <p>
                                            {collapsible.key === "wellbeing30m" ? (
                                                "Investment: $75 NZD"
                                            ) : collapsible.key === "wellbeing45m" ? (
                                                "Investment: $95 NZD"
                                            ) : null}
                                        </p>
                                        <button type="button" className="book-session" onClick={() => navigateToBooking(collapsible.key)}>Book Session</button>
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                    <div className="coaching-image">
                        <img src={thirdImage} alt="Wellbeing" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Coaching;