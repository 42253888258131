import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

const DropdownMenu = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const maxMobileWidth = 1119;

    useEffect(() => {
        const updateScreenWidth = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', updateScreenWidth);

        return () => {
            window.removeEventListener('resize', updateScreenWidth);
        };
    }, []);

    const handleMouseEnter = () => {
        if (screenWidth > maxMobileWidth) {
            setIsDropdownOpen(true);
        }
    };

    const handleMouseLeave = () => {
        if (screenWidth > maxMobileWidth) {
            setIsDropdownOpen(false);
        }
    };

    const handleDropdownClick = () => {
        if (screenWidth <= maxMobileWidth) {
            setIsDropdownOpen(!isDropdownOpen);
            const element = document.getElementById('dropdown-menu');
            if (element !== null) {
                if (isDropdownOpen) {
                    element.classList.remove('show');
                } else {
                    element.classList.add('show');
                }
            }
        }
    };

    const location = useLocation();

    useEffect(() => {
        setIsDropdownOpen(false);
        const element = document.getElementById('dropdown-menu');
        if (element !== null) {
            element.classList.remove('show');
        }
    }, [location]);

    const navigateToCoaching = (section) => {
        if (location.pathname === '/coaching') {
            window.scrollTo(0, 0);
            document.getElementById(section).scrollIntoView();
        }
    };

    return (
        <li
            className="nav-item dropdown"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {screenWidth > maxMobileWidth ? (
                <Link
                    className="nav-link dropdown-toggle"
                    role="button"
                    aria-expanded="false"
                    to="/coaching"
                >
                    Coaching
                </Link>
            ) : (
                <span
                    className="nav-link dropdown-toggle"
                    role="button"
                    aria-expanded="false"
                    onClick={handleDropdownClick}
                >
                    Coaching
                </span>
            )}
            <ul className={`dropdown-menu${isDropdownOpen ? ' show' : ''}`} id="dropdown-menu">
                <li>
                    <Link
                        to="/coaching"
                        className="dropdown-item"
                        state={{section: "free-consultation-coaching"}}
                        onClick={() => navigateToCoaching('free-consultation-coaching')}
                    >
                        Free Consultation
                    </Link>
                </li>
                <li>
                    <Link
                        to="/coaching"
                        className="dropdown-item"
                        state={{section: "nutrition-coaching"}}
                        onClick={() => navigateToCoaching('nutrition-coaching')}
                    >
                        Nutrition
                    </Link>
                </li>
                <li>
                    <Link
                        to="/coaching"
                        className="dropdown-item"
                        state={{section: "strength-training-coaching"}}
                        onClick={() => navigateToCoaching('strength-training-coaching')}
                    >
                        Strength Training
                    </Link>
                </li>
                <li>
                    <Link
                        to="/coaching"
                        className="dropdown-item"
                        state={{section: "wellbeing-coaching"}}
                        onClick={() => navigateToCoaching('wellbeing-coaching')}
                    >
                        Wellbeing
                    </Link>
                </li>
            </ul>
        </li>
    );
};

export default DropdownMenu;
