import { Link } from "react-router-dom"
import React from "react";
import logo from "../images/TreeLogo.png";
import fb from "../images/fb-logo.png"
import ig from "../images/ig-logo.png"

const Footer = () => {
    return (
        <footer className="footer">
            <img src={logo} alt="logo"/>
            <p>Jacqui Gee Health & Wellness Coaching<br/>
            +64 21 149 1312<br/>
            Wellington, New Zealand<br/>
            jacqui@healthfitcollective.co.nz</p>
            <Link to="/contact">Contact Me</Link>
            <div id="social-media">
                <Link to="https://www.facebook.com/Jacqui-Gee-Health-Wellness-Coach-114898924030320/">
                    <img src={fb} loading="lazy" alt="Facebook logo"/>
                </Link>
                <Link to="https://www.instagram.com/jacquigeewellnesscoach/">
                    <img src={ig} loading="lazy" alt="Instagram logo"/>
                </Link>
            </div>
            <div id="copyright">
                <p>© 2023 Jacqui Gee Health & Wellness. All rights reserved.</p>
            </div>
        </footer>
    )
}

export default Footer