import React, { useState, useEffect } from 'react';

const Blog = () => {
    const [screenWidth, setScreenWidth] = useState(500);

    useEffect(() => {
        function handleResize() {
            const currentScreenWidth = window.screen.width
            const facebookPage = document.getElementById("facebook-page")
            if (currentScreenWidth < 500) {
                facebookPage.style.width = `${currentScreenWidth}px`
                setScreenWidth(currentScreenWidth)
            } else {
                facebookPage.style.width = "500px"
            }
        } 

        handleResize()
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        };
    }, []);

    const facebookPluginUrl = `https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FJacquiGeeHealthCoach&tabs=timeline&width=${screenWidth}&height=1000&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=true&appId=7642478315778655`;

    return (
        <div className="blog">
            <h1>Blog</h1>
            <div className="blog-content">
                <div id="facebook-page">
                    <iframe
                        src={facebookPluginUrl}
                        width={`${screenWidth}px`}
                        height="1000px"
                        style={{
                            border: 'none',
                            overflow: 'hidden'
                        }}
                        scrolling="no"
                        frameBorder="0"
                        allowFullScreen={true}
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    ></iframe>
                </div>
            </div>
        </div>
    )
}

export default Blog;