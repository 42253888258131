import React, { useState } from 'react';

import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from "./pages/Home.js"
import Testimonials from "./pages/Testimonials.js"
import Blog from "./pages/Blog.js"
import Coaching from "./pages/Coaching.js"
import Contact from "./pages/Contact.js"
import Booking from "./pages/Booking.js"
import ManageBooking from "./pages/ManageBooking.js"
import Profile from "./pages/Profile.js"
import SetToTop from "./components/SetToTop.js"
import Navbar from "./components/Navbar.js"
import Footer from "./components/Footer.js"
import './scss/main.scss';

function App() {
	return (
		<div className="App">
			<BrowserRouter>
				<SetToTop />
				<Navbar />
				<div className="pages">
					<Routes>
						<Route
							path="/"
							element={<Home />}
						/>
						<Route
							path="/coaching"
							element={<Coaching />}
						/>
						<Route 
							path="/coaching/booking"
							element={<Booking />}
						/>
						<Route 
							path="/coaching/booking/manage/:bookingId"
							element={<ManageBooking />}
						/>
						<Route
							path="/testimonials"
							element={<Testimonials />}
						/>
						<Route
							path="/blog"
							element={<Blog />}
						/>
						<Route
							path="/contact"
							element={<Contact />}
						/>
						<Route 
							path="/profile"
							element={<Profile />}
						/>
					</Routes>
				</div>
				<Footer />
			</BrowserRouter>
		</div>
	);
}

export default App;
