import React, { useState } from 'react';
import axios from 'axios';

const ContactForm = () => {
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [comments, setComments] = useState("");

    const [emailError, setEmailError] = useState("")
    const [subjectError, setSubjectError] = useState("")
    const [commentsError, setCommentsError] = useState("")

    const [successfulContact, setSuccessfulContact] = useState("")

    // Manage the contact dropdowns
    const [collapsibles, setCollapsibles] = useState([
        { key: 0, isActive: false },
        { key: 1, isActive: false },
        { key: 2, isActive: false },
        { key: 3, isActive: false },
        { key: 4, isActive: false }
    ])

    const handleDropdownClick = (key) => {
        setCollapsibles((prevCollapsibles) =>
            prevCollapsibles.map((collapsible) => {
                if (collapsible.key === key) {
                    return { ...collapsible, isActive: !collapsible.isActive };
                }
                return collapsible;
            })
        )
    }


    // Function to set the border colour
    function setBorderColour(id, colour) {
        const element = document.getElementById(id) 
        if (element != null) {
            element.style.borderColor = colour
        }
    }

    // Function to clear login errors
    function clearErrors() {
        // Email error
        setEmailError("")
        setBorderColour("email", "#dee2e6")

        // Subject error
        setSubjectError("")
        setBorderColour("subject", "#dee2e6")

        // Comments error
        setCommentsError("")
        setBorderColour("comments", "#dee2e6")

        setSuccessfulContact("")
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        clearErrors()
        // Validate this data
        let validForm = true

        const contactEmail = email.replace(/\s+/g, "")
        const contactSubject = subject.trim()
        const contactComments = comments.trim()

        // Email
        if (contactEmail.length === 0) {
            setEmailError("Email is required.")
            // Set the border colour
            setBorderColour("email", "red")
            validForm = false
        }

        // Subject
        if (contactSubject.length === 0) {
            setSubjectError("Subject is required.")
            // Set the border colour
            setBorderColour("subject", "red")
            validForm = false
        }

        // Comments
        if (contactComments.length === 0) {
            setCommentsError("Comments are required.")
            // Set the border colour
            setBorderColour("comments", "red")
            validForm = false
        }

        // Manage the email address
        const minimumEmailLength = 4
        const maximumEmailLength = 128
        if (contactEmail.length > 0) {
            if (contactEmail.length > maximumEmailLength || contactEmail.length < minimumEmailLength) {
                setEmailError(`Email must be ${minimumEmailLength}-${maximumEmailLength} characters.`)
                // Set the border colour
                setBorderColour("email", "red")
                validForm = false
            } else if (!(contactEmail.replace(/[^@]/g, "").length === 1 && contactEmail.split('@')[contactEmail.split('@').length - 1].includes('.'))) {
                setEmailError("Invalid email address.")
                // Set the border colour
                setBorderColour("email", "red")
                validForm = false 
            }
        }

        if (validForm) {
            const emailData = {
                email: contactEmail,
                subject: contactSubject, 
                comments: contactComments
            }

            try {
                axios.post(`${process.env.REACT_APP_BACKEND}/email/send`, emailData)
                setSuccessfulContact("Contacted Successfully")
                setEmail("")
                setSubject("")
                setComments("")
            } catch (error) {
            }
        }
    };

    return (
        <div className="contact">
            <div className="contact-form">
                <h1>Contact Jacqui</h1>
                <form className="contactForm" onSubmit={handleSubmit} noValidate>
                    <span className="successful-message" id="contact-successful">{successfulContact}</span>
                    <div>
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <span className="error-message" id="email-error">{emailError}</span>
                    <div>
                    <label htmlFor="subject">Subject:</label>
                    <input
                        type="text"
                        id="subject"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                    />
                    </div>
                    <span className="error-message" id="subject-error">{subjectError}</span>
                    <div>
                    <label htmlFor="comments">Comments:</label>
                    <textarea
                        id="comments"
                        value={comments}
                        onChange={(e) => setComments(e.target.value)}
                    />
                    </div>
                    <span className="error-message" id="email-error">{commentsError}</span>
                    <button type="submit">Contact</button>
                </form>
            </div>
            <div className="frequently-asked-questions">
                <h1>Frequently Asked Questions</h1>
                {/* Include collapsibles */}
                <div className="questions">
                {collapsibles.map((collapsible) => (
                    <React.Fragment key={collapsible.key}>
                        <button
                            type="button"
                            className={`coaching-collapsible collapsible ${collapsible.isActive ? 'active' : ''}`}
                            onClick={() => handleDropdownClick(collapsible.key)}
                        >
                            {collapsible.key === 0 ? (
                                "Can you explain Jacqui's qualifications and experience?"
                            ) : collapsible.key === 1 ? (
                                "Are there any specific age groups or demographics that Jacqui works with?"
                            ) : collapsible.key === 2 ? (
                                "Are virtual consultations available with Jacqui?"
                            ) : collapsible.key === 3 ? (
                                "What sets Jacqui apart from other health and wellness practitioners?"
                            ) : collapsible.key === 4 ? (
                                "Can Jacqui provide dietary recommendations or meal plans?"
                            ) : null}
                        </button>
                        <div
                            className={`collapsible-content content ${collapsible.isActive ? 'active' : ''}`}
                            style={{ maxHeight: collapsible.isActive ? '100%' : 0 }}
                        >
                            <p>
                                {collapsible.key === 0 ? (
                                    "Jacqui has been involved in fitness, health and nutrition for over 30 years. Jacqui was a competitive swimmer and has worked in the fitness industry in Australia and New Zealand. " +
                                    "Qualifications: Certificate III & IV in Fitness, REPS NZ Register of Exercise Professionals, Pelvic Floor Workshop with Continence NZ, Essential First Aid Course with New Zealand Red Cross," +
                                    "Mental Health First Responder with CoLiberate - Workplace Wellbeing, Diploma in Personal Nutrition, Diploma in Sports and Exercise Nutrition, Diploma in Dieta and Weight Management," +
                                    "Holistic Movement Coach - Certificate of Attendance (June 2022 & Feb 2023), and Essential Communication Skills with Brightstar."
                                ) : collapsible.key === 1 ? (
                                    "Jacqui has a wide range of clients ranging from teenagers to clients in their 80s, it's never too early or late to start on your health and wellness journey. " +
                                    "Regardless of your age, where you live or what challenge you are facing Jacqui can help guide and support you."
                                ) : collapsible.key === 2 ? (
                                    "Yes, any sessions can be done online or in person at HealthFit Collective gym in Wellington."
                                ) : collapsible.key === 3 ? (
                                    "Jacqui uses a holistic approach that is based on the concept that each person is unique with individualised lifestyle and exercise requirements. " +
                                    "Jacqui is passionate about showing people how they can stay young, fit and healthy so they can live their life doing what they love."
                                ) : collapsible.key === 4 ? (
                                    "Yes, these can be covered in a nutrition consultation."
                                ) : null}
                            </p>
                        </div>
                    </React.Fragment>
                ))}
                </div>


            </div>
        </div>
    );
};

export default ContactForm;
