import React, { useState } from 'react';
import axios from 'axios';

import firstImage from "../images/IMG_0915-2.jpg"
import secondImage from "../images/IMG_0918-2.jpg"

const Testimonials = () => {
    return (
        <div className="testimonials">
            <h1>Client Stories</h1>
            <div className="cards card-row">
                <div className="card two-card">
                    <img src={firstImage}/>
                    <p>I've been seeing Jacqui every Saturday for almost two years and I feel great.
                        I'm 50 and want to be fit and strong so I can go hiking and be comfortably active.
                        Along with my strength we have increased my flexibility and worked on my nutrition.
                        My bones are denser, my clothes fit better and I have a much better understanding of what my diet should be if I want to have a clear and well functioning mind.
                        Jacqui is kind, supportive and understanding... But will kick my butt if I need it!</p>
                    <div className="client">
                        <p><span className="client-name">Karmyn Lyons</span></p>
                    </div>
                </div>
                <div className="card two-card">
                    <img src={secondImage}/>
                    <p>Jacqui has made a significant difference in how I train and what I eat in navigating my menopause journey.
                        The results have left me stronger and happily revisiting wardrobe items that had become uncomfortable.</p>
                    <div className="client">
                        <p><span className="client-name">Penny McDonald</span></p>
                    </div>
                </div>
            </div>
            <div className="cards card-row">
                <div className="card two-card">
                    <img src={firstImage}/>
                    <p>Jacqui is great at listening to what is important and working out a programme / giving advice to suit. 
                        I wanted a manageable programme aligned to my other commitments, and nutrition advice to keep my weight balanced and energy up - and she delivered on both. 
                        She is always supportive and encouraging, and never judgemental.</p>
                    <div className="client">
                        <p><span className="client-name">Linda Ashton</span></p>
                    </div>
                </div>
                <div className="card two-card">
                    <img src={secondImage}/>
                    <p>Jacqui did a comprehensive assessment of my needs and baseline condition and developed a program to address specific areas that needed attention and is working well.</p>
                    <div className="client">
                        <p><span className="client-name">Kim Moleta</span></p>
                    </div>
                </div>
            </div>
            <div className="cards card-row">
                <div className="card two-card">
                    <img src={firstImage}/>
                    <p>Jacqui is passionate about wellness - she is so knowledgeable about nutrition and finding the exercise plan that suits you. Love knowing that I’m in the best hands.</p>
                    <div className="client">
                        <p><span className="client-name">Lucie Hyett</span></p>
                    </div>
                </div>
                <div className="placeholder-card"></div>
            </div>
        </div>
    )
}

export default Testimonials;