import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const ManageBooking = () => {
    const { bookingId } = useParams();
    const [userBookings, setUserBookings] = useState([])

    // Format the date
    function formatDate(date) {
        const day = date.toDateString()
        let time = date.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        }).toLowerCase();
      
        return `${time} on ${day}`;
    }

    useEffect(() => {
        // Get the booking
        const getBooking = async() => {
            try {
                const bookingResponse = await axios.post(`${process.env.REACT_APP_BACKEND}/booking/one`, { _id: bookingId });
                setUserBookings(bookingResponse.data)
            } catch (error) {
                // Alert the user that there is no booking with that id
            }
        }
        getBooking()
    }, [])

    return (
        <div className="booking-list">
            {userBookings.map(booking => (
                <div key={booking._id} className="booking-card">
                    <div className="booking-info">
                        <div className="info-item">
                            <strong>Start Time:</strong> {formatDate(new Date(booking.bookingStartTime))}
                        </div>
                        <div className="info-item">
                            <strong>End Time:</strong> {formatDate(new Date(booking.bookingEndTime))}
                        </div>
                        <div className="info-item">
                            <strong>First Name:</strong> {booking.firstName}
                        </div>
                        <div className="info-item">
                            <strong>Last Name:</strong> {booking.lastName}
                        </div>
                        <div className="info-item">
                            <strong>Email:</strong> {booking.emailAddress}
                        </div>
                        <div className="info-item">
                            <strong>Phone Number:</strong> {booking.phoneNumber}
                        </div>
                        <div className="info-item">
                            <strong>Comments:</strong> {booking.comments}
                        </div>
                        <div className="delete-booking cancel-booking">
                            <button className="delete-booking-button">Cancel Booking</button>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
};

export default ManageBooking;