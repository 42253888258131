import React, { useEffect, useState, useRef, useLayoutEffect } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import Modal from 'react-bootstrap/Modal';
import Calender from "react-calendar";
import axios from 'axios';
import 'react-calendar/dist/Calendar.css';
import { isBefore, startOfDay, getMonth, getYear, addDays, addMinutes, getDay } from 'date-fns';
import { decodeToken } from "react-jwt";

const Profile = () => {
    const [isBookingModalVisible, setBookingModalVisibility] = useState(false);
    const [isAvailabilityFormVisible, setAvailabilityFormVisiblity] = useState(false);
    const [isBookingFormVisible, setBookingFormVisibility] = useState(false);
    const [isWorkoutFormVisible, setWorkoutFormVisible] = useState(false);
    const [isCongratulationsVisible, setCongratulationsVisible] = useState(false)
    const [editWorkoutVisible, setEditWorkoutVisible] = useState(false)
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);

    // Exercises & Workouts
    const [workouts, setWorkouts] = useState([])
    const [workoutTitle, setWorkoutTitle] = useState("")
    const [exercises, setExercises] = useState([]) // List of objects
    const [exerciseIndex, setExerciseIndex] = useState(0)

    // Workout errors
    const [workoutError, setWorkoutError] = useState("")

    // Manage the selected date
    const [date, setDate] = useState(new Date())
    const [displayDate, setDisplayDate] = useState(new Date())

    // Manage the times for adding a booking
    const [startHourMinute, setStartHourMinute] = useState("00:00")
    const [endHourMinute, setEndHourMinute] = useState("00:00")

    // Manage the times for modifying a booking
    const [startModifyHourMinute, setStartModifyHourMinute] = useState("00:00")
    const [endModifyHourMinute, setEndModifyHourMinute] = useState("00:00")
    
    // const [startDisplayModifyHourMinute, setStartDisplayModifyHourMinute] = useState("00:00")
    // const [endDisplayModifyHourMinute, setEndDisplayModifyHourMinute] = useState("00:00")

    const [month, setMonth] = useState(getMonth(new Date()))
    const [year, setYear] = useState(getYear(new Date()))
    const [direction, setDirection] = useState(1) // 1 for forward, -1 for backward
    const [totalMonths, setTotalMonths] = useState(0)

    const [isAvailabilityChecked, setIsAvailabilityChecked] = useState(true)
    const [isRecurringChecked, setIsRecurringChecked] = useState(true)
    const [isRecurrenceChecked, setIsRecurrenceChecked] = useState(false)
    const [isCustomSession, setIsCustomSession] = useState(true)
    const [isCustomBooking, setIsCustomBooking] = useState(true)
    const [isBookingChecked, setIsBookingChecked] = useState(false)
    const [modalNameError, setModalNameError] = useState('')
    const [isRecurringEvent, setRecurringEvent] = useState(false)
    const [isRecurringBooking, setRecurringBooking] = useState(false)

    // For manage the recurrence days
    const [mondaySelected, setMondaySelected] = useState(false)
    const [tuesdaySelected, setTuesdaySelected] = useState(false)
    const [wednesdaySelected, setWednesdaySelected] = useState(false)
    const [thursdaySelected, setThursdaySelected] = useState(false)
    const [fridaySelected, setFridaySelected] = useState(false)
    const [saturdaySelected, setSaturdaySelected] = useState(false)
    const [sundaySelected, setSundaySelected] = useState(false)

    // Manage events
    const [calendarEvents, setCalendarEvents] = useState([])
    const [showCalendar, setShowCalendar] = useState(false)

    // Available time 
    const [startAvailableTime, setStartAvailableTime] = useState(new Date())
    const [endAvailableTime, setEndAvailableTime] = useState(new Date())

    const [selectedValue, setSelectedValue] = useState("free30m")
    const [selectedBookingValue, setSelectedBookingValue] = useState("")

    // Booking fields
    const [bookingFirstName, setBookingFirstName] = useState("")
    const [bookingLastName, setBookingLastName] = useState("")
    const [bookingEmail, setBookingEmail] = useState("")
    const [bookingEmailError, setBookingEmailError] = useState("")
    const [bookingPhone, setBookingPhone] = useState("")
    const [bookingPhoneError, setBookingPhoneError] = useState("")
    const [bookingComments, setBookingComments] = useState("")

    // Selected booking fields
    const [selectedBookingStartTime, setSelectedBookingStartTime] = useState(new Date())
    const [selectedBookingEndTime, setSelectedBookingEndTime] = useState(new Date())
    const [selectedBookingFirstName, setSelectedBookingFirstName] = useState("")
    const [selectedBookingLastName, setSelectedBookingLastName] = useState("")
    const [selectedBookingType, setSelectedBookingType] = useState("")
    const [selectedBookingEmail, setSelectedBookingEmail] = useState("")
    const [selectedBookingPhone, setSelectedBookingPhone] = useState("")
    const [selectedBookingComments, setSelectedBookingComments] = useState("")

    // Updated booking fields
    const [updatedBookingStartTime, setUpdatedBookingStartTime] = useState(new Date())
    const [updatedBookingEndTime, setUpdatedBookingEndTime] = useState(new Date())
    const [updatedBookingFirstName, setUpdatedBookingFirstName] = useState("00:00")
    const [updatedBookingLastName, setUpdatedBookingLastName] = useState("00:00")
    const [updatedBookingType, setUpdatedBookingType] = useState("")
    const [updatedBookingEmail, setUpdatedBookingEmail] = useState("")
    const [updatedBookingPhone, setUpdatedBookingPhone] = useState("")
    const [updatedBookingComments, setUpdatedBookingComments] = useState("")

    // Selected workout information
    const [workoutInformation, setWorkoutInformation] = useState(null)

    // User status
    const [isLoggedIn, setLoggedIn] = useState(false)
    const [isAdministrator, setAdministrator] = useState(false)
    const [user, setUser] = useState(null)

    const [userBookings, setUserBookings] = useState([])

    function previousButtonClicked() {
        const nextButton = document.querySelector(".fc-next-button")
        nextButton.disabled = false
    }

    function nextButtonClicked() {
        const prevButton = document.querySelector(".fc-prev-button")
        prevButton.disabled = false
    }

    function todayButtonClicked() {
        const nextButton = document.querySelector(".fc-next-button")
        nextButton.disabled = false
    }

    // Function for handling when the phone number input is updated
    const handlePhoneNumberChange = (e) => {
        const validatedPhoneNumber = e.target.value.replace(/[^0-9\s]/g, "")
        setBookingPhone(validatedPhoneNumber)
    }

    const handleBookingPhoneChange = (e) => {
        const validatedPhoneNumber = e.target.value.replace(/[^0-9\s]/g, "")
        setUpdatedBookingPhone(validatedPhoneNumber)
    }

    // Function to set the border colour
    function setBorderColour(id, colour) {
        const element = document.getElementById(id) 
        if (element != null) {
            element.style.borderColor = colour
        }
    }

    function clearBookingFields() {
        setBookingFirstName("")
        setBookingLastName("")
        setBookingEmail("")
        setBookingPhone("")
    }

    // Clear booking booking errors
    function clearBookingErrors() {
        setModalNameError("") // Change the name of this 
        setBorderColour("addCalendarFirstName", "#dee2e6")
        setBorderColour("addCalendarLastName", "#dee2e6")

        // Clear the email errors
        setBookingEmailError("")
        setBorderColour("booking-email", "#dee2e6")

        // Clear the phone errors
        setBookingPhoneError("")
        setBorderColour("signup-phone-container", "#dee2e6")
    }

    // Capitalise first letter of a string
    function capitaliseFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    // Format the date
    function formatDate(date) {
        const day = date.toDateString()
        let time = date.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        }).toLowerCase();
      
        return `${time} on ${day}`;
    }

    const sessionLength = {
        "free30m": 30,
        "nutritionInitial": 60,
        "nutritionFollowUp": 30,
        "movementAssessment": 45,
        "strengthTraining30m": 30,
        "strengthTraining45m": 45,
        "wellbeing30m": 30,
        "wellbeing45m": 45
    }

    const formattedSessionName = {
        "free30m": "Initial Consultation",
        "nutritionInitial": "Initial Nutrition Consultation",
        "nutritionFollowUp": "Follow Up Nutrition Consultation",
        "movementAssessment": "Movement Assessment",
        "strengthTraining30m": "Strength Training (30 mins)",
        "strengthTraining45m": "Strength Training (45 mins)",
        "wellbeing30m": "Wellbeing (30 mins)",
        "wellbeing45m": "Wellbeing (45 mins)",
        "custom": "Custom"
    }

    function getBookingEvents() {
        let bookingEvents = []
        calendarEvents.forEach(event => {
            if (event.class === "booking") {
                bookingEvents.push(event)
            }
        })
        return bookingEvents
    }

    useLayoutEffect(() => {
        const userToken = localStorage.getItem('user');
    
        if (userToken) {
            // Parse the user token to get user information
            const userData = decodeToken(userToken);

            // Check and set the administrator status
            const isAdmin = userData.administrator;
            const userId = userData._id;
            setUser(userId)
            setAdministrator(isAdmin)

            // Set logged in
            setLoggedIn(true);
        } else {
            setLoggedIn(false);
        }
    }, [])

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const eventResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/calendar/all`);
                const eventData = eventResponse.data

                const availabilityArray = []
                
                let lastAvailabilityTime = new Date()

                eventData.forEach(event => {
                    if (!event.isRecurrence) {
                        const startTime = new Date(event.startTime)
                        const endTime = new Date(event.endTime)
                        const currentTime = new Date()
                        // Ensure the event is in the future
                        if (endTime > currentTime) {
                            const availabilityEvent = {
                                class: "available",
                                start: event.startTime,
                                end: event.endTime,
                                display: "background",
                                color: "white"
                            }

                            // Check if the event is active currently
                            if (startTime < currentTime) {
                                availabilityEvent.start = currentTime
                                lastAvailabilityTime = event.endTime
                            }
                            
                            availabilityArray.push(availabilityEvent)
                        } else {
                            // Delete the event
                        }
                    } else {
                        const recurrence = event.recurrence

                        const startHours = recurrence.startHour
                        const startMinutes = recurrence.startMinute
                        const endHours = recurrence.endHour
                        const endMinutes = recurrence.endMinute

                        const exceptions = recurrence.exceptions.map(exception => new Date(exception))

                        // 27 weeks in the future (minus one day to account)
                        const today = new Date()
                        const targetDate = new Date(today.getTime() + 188 * 86400000) 

                        // Iterate from today to the target date
                        let currentDate = today
                        const daysArray = recurrence.days

                        while (currentDate < targetDate) {
                            const currentDay = currentDate.getDay()

                            const isException = exceptions.some(exception => {
                                const midnightException = new Date(exception);
                                midnightException.setHours(0, 0, 0, 0);
                                const currentMidnight = new Date(currentDate)
                                currentMidnight.setHours(0, 0, 0, 0);
                                return midnightException.getTime() === currentMidnight.getTime();
                            });

                            if (daysArray.includes(currentDay) && !isException) {
                                // Start time
                                const currentStartTime = new Date(currentDate)
                                currentStartTime.setHours(startHours, startMinutes, 0, 0)

                                // End time
                                const currentEndTime = new Date(currentDate)
                                currentEndTime.setHours(endHours, endMinutes, 0, 0)

                                // Start in past
                                if (new Date() > currentStartTime) {
                                    lastAvailabilityTime = currentEndTime
                                    currentStartTime.setDate(new Date())
                                }

                                const availabilityEvent = {
                                    class: "available",
                                    start: currentStartTime,
                                    end: currentEndTime,
                                    recurrence: true,
                                    display: 'background',
                                    color: "white"
                                }
                                
                                // Ensure that the dates are in the future
                                if (currentEndTime > new Date()) {
                                    availabilityArray.push(availabilityEvent)
                                }
                            }
                            
                            // Increment by 1 day
                            currentDate.setDate(currentDate.getDate() + 1); 
                        }
                    }
                })

                // This is imperative
                availabilityArray.sort((a, b) => new Date(a.start) - new Date(b.start))

                // Add the unavailability
                let unavailabilityArray = [] 
                availabilityArray.forEach(event => {
                    // Only add unavailability if the start time is before the end time
                    if (new Date(lastAvailabilityTime) < new Date(event.start)) {
                        const unavailabilityEvent = {
                            class: "unavailable",
                            start: lastAvailabilityTime,
                            end: event.start,
                            display: "background",
                            color: "grey"
                        }
                        unavailabilityArray.push(unavailabilityEvent)
                        // Next event starts when the last one ended
                        lastAvailabilityTime = event.end
                    }
                })

                // Final event
                const finalUnavailabilityEvent = {
                    class: "unavailable",
                    start: lastAvailabilityTime,
                    end: new Date(new Date(new Date().getTime() + (193 * 86400000)).setHours(0, 0, 0, 0)),
                    display: "background",
                    color: "grey"            
                }
                unavailabilityArray.push(finalUnavailabilityEvent)

                // Get the booking events
                const bookingResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/booking/all`);

                let bookingEvents = []
                // Show session type as well in the time somehow?
                bookingResponse.data.forEach(booking => {
                    if (!booking.isRecurrence) {
                        const startTime = new Date(booking.bookingStartTime)
                        const endTime = new Date(booking.bookingEndTime)
                        const firstName = booking.firstName
                        const lastName = booking.lastName
                        const bookingType = booking.sessionType // Format this later
                        const emailAddress = booking.emailAddress
                        const phoneNumber = booking.phoneNumber ? booking.phoneNumber.toString() : ""
                        const comments = booking.comments
                        
                        // Ensure the booking is in the future or currently
                        if (new Date().getTime() < endTime.getTime()) {
                            const formattedComments = comments ? "Comments: " + comments : ""
                            const formattedPhone = phoneNumber ? "Phone: +64 " + phoneNumber + (formattedComments.length > 0 ? "\n" : "") : "";
                            const formattedEmail = emailAddress ? "Email: " + emailAddress + (formattedPhone.length > 0 || formattedComments.length > 0 ? "\n" : "") : "";

                            const bookingEvent = {
                                class: "booking",
                                start: startTime,
                                end: endTime,
                                firstName: capitaliseFirstLetter(firstName),
                                lastName: capitaliseFirstLetter(lastName),
                                type: bookingType,
                                emailAddress: emailAddress,
                                phoneNumber: phoneNumber,
                                comments: comments,
                                title: capitaliseFirstLetter(firstName) + " " + capitaliseFirstLetter(lastName) + "\n" 
                                + "Type: " + formattedSessionName[bookingType]
                                + (formattedEmail.length > 0 || formattedPhone.length > 0 || formattedComments.length > 0 ? "\n" : "")
                                + formattedEmail
                                + formattedPhone
                                + formattedComments
                            }
                            bookingEvents.push(bookingEvent)
                        }
                    } else {
                        // Iterate
                        // 27 weeks in the future (minus one day to account)
                        const today = new Date()
                        const targetDate = new Date(today.getTime() + 188 * 86400000) 

                        const recurrence = booking.recurrence
                        const startHours = recurrence.startHour
                        const startMinutes = recurrence.startMinute
                        const endHours = recurrence.endHour
                        const endMinutes = recurrence.endMinute
                        const daysArray = recurrence.days
                        // DO EXCEPTIONS

                        const bookingType = booking.sessionType
                        const firstName = booking.firstName
                        const lastName = booking.lastName
                        const emailAddress = booking.emailAddress
                        const phoneNumber = booking.phoneNumber ? booking.phoneNumber.toString() : ""
                        const comments = booking.comments
        
                        // Iterate from today to the target date
                        let currentDate = new Date(today)                    
                        while (currentDate < targetDate) {
                            const currentDay = currentDate.getDay()
    
                            if (daysArray.includes(currentDay)) {
                                // Start time
                                const currentStartTime = new Date(currentDate)
                                currentStartTime.setHours(startHours, startMinutes, 0, 0)
    
                                // End time
                                const currentEndTime = new Date(currentDate)
                                currentEndTime.setHours(endHours, endMinutes, 0, 0)

                                const formattedComments = comments ? "Comments: " + comments : ""
                                const formattedPhone = phoneNumber ? "Phone: +64 " + phoneNumber + (formattedComments.length > 0 ? "\n" : "") : "";
                                const formattedEmail = emailAddress ? "Email: " + emailAddress + (formattedPhone.length > 0 || formattedComments.length > 0 ? "\n" : "") : "";
    
                                const bookingEvent = {
                                    class: "booking",
                                    start: currentStartTime,
                                    end: currentEndTime,
                                    firstName: capitaliseFirstLetter(firstName),
                                    lastName: capitaliseFirstLetter(lastName),
                                    type: bookingType,
                                    emailAddress: emailAddress,
                                    phoneNumber: phoneNumber,
                                    comments: comments,
                                    recurrence: true,
                                    title: capitaliseFirstLetter(firstName) + " " + capitaliseFirstLetter(lastName) + "\n" 
                                    + "Type: " + formattedSessionName[bookingType]
                                    + (formattedEmail.length > 0 || formattedPhone.length > 0 || formattedComments.length > 0 ? "\n" : "")
                                    + formattedEmail
                                    + formattedPhone
                                    + formattedComments
                                }
                                
                                // Ensure that the dates are in the future
                                if (currentStartTime > new Date()) {
                                    bookingEvents.push(bookingEvent)
                                }
                            }
                            
                            // Increment by 1 day
                            currentDate.setDate(currentDate.getDate() + 1); 
                        }
                    }
                    // Previous button
                    const prevButton = document.querySelector(".fc-prev-button")
                    prevButton.disabled = true
                    prevButton.addEventListener("click", previousButtonClicked)

                    // Next button
                    const nextButton = document.querySelector(".fc-next-button")
                    nextButton.addEventListener("click", nextButtonClicked)
                    
                    // Today button
                    const todayButton = document.querySelector(".fc-today-button")
                    todayButton.addEventListener("click", todayButtonClicked)
                })

                const currentCalendarEvents = [untilNowEvent, ...unavailabilityArray, ...availabilityArray, ...bookingEvents]
                
                // Show the calendar
                setShowCalendar(true)
                setCalendarEvents(currentCalendarEvents)

            } catch (error) {
            }
        }

        const fetchBookings = async (userId) => {
            const userResponse = await axios.post(`${process.env.REACT_APP_BACKEND}/booking/get`, { userId: userId })
            // Set this data with a state and display it
            setUserBookings(userResponse.data)
        }

        const fetchWorkouts = async (userId) => {
            const workoutResponse = await axios.post(`${process.env.REACT_APP_BACKEND}/workout/get`, { userId: userId })
            // Set this data with a state and display it
            const workouts = workoutResponse.data;

            // Use the map function to iterate over each workout
            const extractedWorkouts = workouts.map((workout) => {
                // Access the exercises and workoutTitle fields for each workout
                const workoutId = workout._id
                const workoutTitle = workout.workoutTitle;
                const exercises = workout.exercises;

                // Return the extracted information for each workout
                return {
                    id: workoutId,
                    workoutTitle: workoutTitle,
                    exercises: exercises
                };
            });

            // Now extractedWorkouts is an array with each element containing exercises and workoutTitle
            // You can set it using setWorkouts if needed
            setWorkouts(extractedWorkouts);
        }

        const userToken = localStorage.getItem('user');
        let isAdmin = false
        let loggedIn = false
        let userId = null
        if (userToken) {
            // Parse the user token to get user information
            const userData = decodeToken(userToken);
            // Check and set the administrator status
            isAdmin = userData.administrator;
            userId = userData._id;
            setUser(userId)
            setAdministrator(isAdmin)

            // Set logged in
            loggedIn = true;
        } else {
            loggedIn = false;
        }

        const untilNowEvent = {
            id: "past",
            start: new Date(new Date().setHours(0, 0, 0, 0)),
            end: new Date(),
            display: 'background',
            color: "black"
        }

        const futureEvent = {
            id: "unavailable",
            start: new Date(),
            end: new Date(new Date(new Date().getTime() + (193 * 86400000)).setHours(0, 0, 0, 0)),
            display: 'background',
            color: "grey"
        }

        if (isAdmin) {
            fetchEvents()
            // Manage the calendar events
            //onEventAdded(futureEvent)
            setCalendarEvents([untilNowEvent, futureEvent])
        } else if (loggedIn) {
            // Fetch bookings and workouts
            fetchBookings(userId)
            fetchWorkouts(userId)
        }
    }, [])

    // Function to show the booking model
    const handleShowBookingModal = () => {
        // Deselect everything
        if (showCalendar) {
            document.activeElement.blur();
            document.documentElement.style.overflowY = 'hidden';
            setBookingModalVisibility(true)
        }
    }

    // Function to show the manage availability booking
    const handleShowAvailabilityFormModal = () => {
        // Deselect everything
        if (showCalendar) {
            document.activeElement.blur();
            document.documentElement.style.overflowY = 'hidden';
            setAvailabilityFormVisiblity(true)
        }
    }

    // Function to show the manage booking form
    const handleShowBookingFormModal = () => {
        if (showCalendar) {
            // Deselect everything
            document.activeElement.blur();
            document.documentElement.style.overflowY = 'hidden';
            setBookingFormVisibility(true)
        } 
    }

    // Function to show the manage workout form
    const handleShowWorkoutFormModal = () => {
        // Deselect everything
        document.activeElement.blur();
        document.documentElement.style.overflowY = 'hidden';
        setWorkoutFormVisible(true) 
    }

    // Function to show the congratulations modal
    const handleShowCongratulationsModal = () => {
        // Deselect everything
        document.activeElement.blur();
        document.documentElement.style.overflowY = 'hidden';
        setCongratulationsVisible(true) 
    }

    // Function to show the edit workout modal
    const handleShowEditWorkoutModal = () => {
        // Deselect everything
        document.activeElement.blur();
        document.documentElement.style.overflowY = 'hidden';
        setEditWorkoutVisible(true) 
    }

    const manageShowEditWorkoutModal = (workout) => {
        // Set the current editing workout 
        setWorkoutInformation(workout)
        setWorkoutTitle(workout.workoutTitle)
        let workoutExercises = workout.exercises 
        let currentExercises = []
        for (let i = 0; i < workoutExercises.length; i++) {
            const currentExercise = workoutExercises[i]
            currentExercise.index = i
            currentExercises.push(currentExercise)
        }
        setExercises(currentExercises)
        setExerciseIndex(currentExercises.length)

        // Show the workout
        handleShowEditWorkoutModal()
    }

    // Function for adding an exercise
    const addExercise = (e) => {
        e.preventDefault()
        setWorkoutError("")
        if (exercises.length < 25) { 
            // ID the exercises in the workout
            setExercises([...exercises, {
                index: exerciseIndex,
                title: "",
                sets: "",
                reps: "",
                description: "",
                titleError: "",
                setsError: "",
                repsError: "",
                descriptionError: ""
            }])
            setExerciseIndex(exerciseIndex + 1)
        }
    }

    // Reset the date when the modal closes
    function resetDate() {
        const modal = document.querySelector(".booking-calendar-modal")
        if (modal) {
            setTimeout(resetDate, 50)
        } else {
            clearBookingErrors()
            setDate(new Date())
            setStartHourMinute("00:00")
            setEndHourMinute("00:00")
            setMondaySelected(false)
            setTuesdaySelected(false)
            setWednesdaySelected(false)
            setThursdaySelected(false)
            setFridaySelected(false)
            setSaturdaySelected(false)
            setSundaySelected(false)
        }
    }

	// Function to close the booking modal
	const handleCloseBookingModal = () => {
        setBookingModalVisibility(false);
        setSelectedTimeSlot(null);
        // WAIT UNTIL THE MENU CLOSES
        resetDate()
        document.documentElement.style.overflowY = 'auto';
	};

    // Function to close the availability form modal 
    const handleCloseAvailabilityFormModal = () => {
        setAvailabilityFormVisiblity(false);
        setSelectedTimeSlot(null);
        // Reset stuff when the modal closes
        document.documentElement.style.overflowY = 'auto';
    }

    const handleCloseBookingFormModal = () => {
        setBookingFormVisibility(false);
        // Reset stuff when the modal closes
        document.documentElement.style.overflowY = 'auto';
    }

    const handleCloseEditWorkoutModal = () => {
        setEditWorkoutVisible(false)
        setExercises([])
        setWorkoutTitle("")
        // Reset stuff when the modal closes
        document.documentElement.style.overflowY = 'auto';
    }

    const handleCloseWorkoutFormModal = () => {
        setWorkoutFormVisible(false);
        setWorkoutError("")
        resetExerciseErrors()
        // Reset stuff when the modal closes
        document.documentElement.style.overflowY = 'auto';
    }

    const handleCloseCongratulationsModal = () => {
        setCongratulationsVisible(false);
        // Reset stuff when the modal closes
        document.documentElement.style.overflowY = 'auto';
    }

    const handleTimeSlotClick = (info) => {
        let availableEvent = false
        let recurringEvent = false
        calendarEvents.forEach(calendarEvent => {
            if (new Date(calendarEvent.start) <= info.date && info.date < new Date(calendarEvent.end)) {
                if (calendarEvent.class === "available") {
                    availableEvent = true
                    recurringEvent = calendarEvent.recurrence;

                    // Check if recurringEvent is undefined or falsy, and set it to false in that case
                    if (!recurringEvent) {
                        recurringEvent = false;
                    }
                    
                    // Set the available time 
                    setStartAvailableTime(new Date(calendarEvent.start))
                    setEndAvailableTime(new Date(calendarEvent.end))

                    // Start
                    let eventStart = new Date(calendarEvent.start)
                    let startHours = eventStart.getHours()
                    let startMinutes = eventStart.getMinutes()
                    let startHoursString = startHours < 10 ? "0" + startHours.toString() : startHours.toString()
                    let startMinutesString = startMinutes < 10 ? "0" + startMinutes.toString() : startMinutes.toString()
                    let startTimeString = startHoursString + ":" + startMinutesString

                    // End
                    let eventEnd = new Date(calendarEvent.end)
                    let endHours = eventEnd.getHours()
                    let endMinutes = eventEnd.getMinutes()
                    let endHoursString = endHours < 10 ? "0" + endHours.toString() : endHours.toString()
                    let endMinutesString = endMinutes < 10 ? "0" + endMinutes.toString() : endMinutes.toString()
                    let endTimeString = endHoursString + ":" + endMinutesString  

                    // Modify
                    setStartModifyHourMinute(startTimeString)
                    setEndModifyHourMinute(endTimeString)
                }
            }
        })

        if (info.date >= new Date()) {
            setDate(info.date)
            setDisplayDate(info.date)
            let hours = info.date.getHours()
            let minutes = info.date.getMinutes()
            let hoursString = hours < 10 ? "0" + hours.toString() : hours.toString()
            let minutesString = minutes < 10 ? "0" + minutes.toString() : minutes.toString()
            let timeString = hoursString + ":" + minutesString

            // Normal
            setStartHourMinute(timeString)
            setEndHourMinute(timeString)
            setRecurringEvent(recurringEvent)

            setSelectedTimeSlot(info.dateStr);
            if (availableEvent) {
                handleShowAvailabilityFormModal()
            } else {
                handleShowBookingModal()
            }
        }
    };

    const handleEventClick = (info) => {
        const event = info.event; // Contains the event object
        const eventInfo = event.extendedProps
        if (eventInfo.class === "booking") {
            handleShowBookingFormModal()

            // Displayed information
            setSelectedBookingStartTime(event.start)
            setSelectedBookingEndTime(event.end)

            setIsCustomBooking(eventInfo.type === "custom")
            setSelectedBookingValue(eventInfo.type)

            setSelectedBookingFirstName(capitaliseFirstLetter(eventInfo.firstName))
            setSelectedBookingLastName(capitaliseFirstLetter(eventInfo.lastName))
            setSelectedBookingType(formattedSessionName[eventInfo.type])
            setSelectedBookingEmail(eventInfo.emailAddress ? eventInfo.emailAddress : "")
            setSelectedBookingPhone(eventInfo.phoneNumber ? eventInfo.phoneNumber.toString() : "")
            setSelectedBookingComments(eventInfo.comments ? eventInfo.comments : "")

            // Updated information
            setDate(event.start)
            // Start
            let eventStart = new Date(event.start)
            let startHours = eventStart.getHours()
            let startMinutes = eventStart.getMinutes()
            let startHoursString = startHours < 10 ? "0" + startHours.toString() : startHours.toString()
            let startMinutesString = startMinutes < 10 ? "0" + startMinutes.toString() : startMinutes.toString()
            let startTimeString = startHoursString + ":" + startMinutesString

            // End
            let eventEnd = new Date(event.end)
            let endHours = eventEnd.getHours()
            let endMinutes = eventEnd.getMinutes()
            let endHoursString = endHours < 10 ? "0" + endHours.toString() : endHours.toString()
            let endMinutesString = endMinutes < 10 ? "0" + endMinutes.toString() : endMinutes.toString()
            let endTimeString = endHoursString + ":" + endMinutesString  
            setUpdatedBookingStartTime(startTimeString)
            setUpdatedBookingEndTime(endTimeString)

            setUpdatedBookingFirstName(capitaliseFirstLetter(eventInfo.firstName))
            setUpdatedBookingLastName(capitaliseFirstLetter(eventInfo.lastName))
            setUpdatedBookingType(eventInfo.type)
            setUpdatedBookingEmail(eventInfo.emailAddress ? eventInfo.emailAddress : "")
            setUpdatedBookingPhone(eventInfo.phoneNumber ? eventInfo.phoneNumber.toString() : "")
            setUpdatedBookingComments(eventInfo.comments ? eventInfo.comments : "")

            let bookingRecurrence = eventInfo.recurrence
            if (!bookingRecurrence) {
                bookingRecurrence = false
            }

            setRecurringBooking(bookingRecurrence)
        }
    }

    const customColumnHeader = (date) => {
        // Implement your custom formatting logic here
        const parts = date.text.split(' ');
        const day = parseInt(parts[1].split('/')[1]); // Extract the day 
        const month = parseInt(parts[1].split('/')[0]); // Extract the month

        const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}`;

        return formattedDate;
    }

    const calendarOptions = {
        headerToolbar: {
            // Need to hide previous/next depending on the day
            left: 'prev,next today',
            center: 'title',
            right: 'addButton'
        },
        initialView: 'timeGridWeek',
        dayHeaderContent: customColumnHeader, // Use the custom formatting function
        allDaySlot: false,
        height: "auto",
        customButtons: {
            addButton: {
                text: "Add",
                click: function() {
                    handleShowBookingModal()
                }
            }
        }
    };

    // Functions for managing the calendar
    const disablePrevButtons = () => {
        const prevButtons = document.querySelectorAll(
            '.react-calendar__navigation__prev-button'
        );
        prevButtons.forEach((button) => {
            if (month <= getMonth(new Date()) && year === getYear(new Date())) {
                button.style.pointerEvents = "none"
                button.style.backgroundColor = "#f0f0f0"
            } else {
                button.style.pointerEvents = "auto"
                button.style.backgroundColor = "#fff"
            }
        })
    }

    const handlePrevButtonClick = () => {
        setDirection(-1);
        setTotalMonths((totalMonths) => (totalMonths - 1))
        setMonth((prevMonth) => (prevMonth > 0 ? prevMonth - 1 : 11));
    };

    const handleNextButtonClick = () => {
        setDirection(1);
        setTotalMonths((totalMonths) => (totalMonths + 1))
        setMonth((prevMonth) => (prevMonth < 11 ? prevMonth + 1 : 0));
    };

    useEffect(() => {
        if (direction === 1 && month === 0) {
            setYear((prevYear) => prevYear + 1);
        } else if (direction === -1 && month === 11) {
            setYear((prevYear) => prevYear - 1);
        } else {
            disablePrevButtons();
        }
    }, [month, direction]);

    useEffect(() => {
        disablePrevButtons();
    }, [year])

    useEffect(() => {
        const nextButtons = document.querySelectorAll(
            '.react-calendar__navigation__next-button'
        );
        nextButtons.forEach((button) => {
            if (totalMonths >= 6) {
                button.style.pointerEvents = "none"
                button.style.backgroundColor = "#f0f0f0"
            } else {
                button.style.pointerEvents = "auto"
                button.style.backgroundColor = "#fff"
            }
        })
    }, [totalMonths])

    function ensureFutureTime(currentSelectedDate) {
        const startTime = startHourMinute.split(":")
        const startHours = parseInt(startTime[0])
        const startMinutes = parseInt(startTime[1])
        const startTotal = 60*startHours + startMinutes

        const endTime = endHourMinute.split(":")
        const endHours = parseInt(endTime[0])
        const endMinutes = parseInt(endTime[1])
        const endTotal = 60*endHours + endMinutes

        const selectedDate = new Date(currentSelectedDate)
        selectedDate.setHours(startHours, startMinutes, 0, 0)

        const selectedEndDate = new Date(currentSelectedDate)
        selectedEndDate.setHours(endHours, endMinutes, 0, 0)

        if (selectedDate > new Date()) {
            // The end cannot be less than the end 
            if (endTotal < startTotal) {
                setEndHourMinute(startTime)
            }
        } 

        // Set the start and end time back
        const formattedDate = new Date()
        const formattedHours = String(formattedDate.getHours()).padStart(2, '0');
        const formattedMinutes = String(formattedDate.getMinutes()).padStart(2, '0');
        const formattedHourMinute = `${formattedHours}:${formattedMinutes}`

        if (selectedDate <= new Date()) {
            setStartHourMinute(formattedHourMinute)
        }

        if (selectedEndDate <= new Date()) {
            setEndHourMinute(formattedHourMinute)
        }
    }

    const onChange = (e) => {
        // Set the date
        const selectedDate = new Date(e)
        const hours = date.getHours()
        const minutes = date.getMinutes() 
        selectedDate.setHours(hours, minutes, 0, 0)

        // Need to manage time
        ensureFutureTime(selectedDate)

        setDate(selectedDate)   

        disablePrevButtons();
    }

    const tileDisabled = ({ date, view }) => {
        if (view === "month") {
            const currentDate = new Date()
            const startOfYear = new Date(currentDate.getFullYear(), 0, 0);
            const currentDayOfYear = Math.floor((currentDate - startOfYear) / (24 * 60 * 60 * 1000));
            const dateDayOfYear = Math.floor((date - startOfYear) / (24 * 60 * 60 * 1000));

            const daysDifference = dateDayOfYear - currentDayOfYear;
            return isBefore(startOfDay(date), startOfDay(currentDate)) || daysDifference > 188;
        }
        return false;
    }

    // This is a recursive function which is called until the button selector is added to both of the buttons
    function getButtons() {
        const prevButton = document.querySelector(
            '.react-calendar__navigation__prev-button'
        );
        const nextButton = document.querySelector(
            '.react-calendar__navigation__next-button'
        );

        if (prevButton && nextButton) {
            prevButton.addEventListener('click', handlePrevButtonClick);
            nextButton.addEventListener('click', handleNextButtonClick);
            disablePrevButtons();
        } else {
            setTimeout(getButtons, 50);
        }
    }

    useEffect(() => {
        setTimeout(getButtons, 50);
        const currentMonth = getMonth(new Date())
        const nextMonth = getMonth(addDays(new Date(), 1))
        if (nextMonth === 0) {
            if (currentMonth === 11) {
                setTotalMonths(1)
            }
        } else {
            setTotalMonths(nextMonth - currentMonth)
        }

        // Remove the event handlers on component unmount
        return () => {
            // Get the previous and next buttons using querySelector
            const prevButton = document.querySelector(
                '.react-calendar__navigation__prev-button'
            );
            const nextButton = document.querySelector(
                '.react-calendar__navigation__next-button'
            );
            if (nextButton && prevButton) {
                prevButton.removeEventListener('click', handlePrevButtonClick);
                nextButton.removeEventListener('click', handleNextButtonClick);
            }
        };
    }, [])

    useEffect(() => {
        // Manage the dates on state change
        if (!isRecurrenceChecked) {
            ensureFutureTime(date)
        }
    }, [isRecurrenceChecked])

    const startTimeManager = (event) => {
        const startTime = event.target.value.split(":")
        const startHours = parseInt(startTime[0])
        const startMinutes = parseInt(startTime[1])
        const startTotal = 60*startHours + startMinutes

        const endTime = endHourMinute.split(":")
        const endHours = parseInt(endTime[0])
        const endMinutes = parseInt(endTime[1])
        const endTotal = 60*endHours + endMinutes

        // Cannot be before now
        const selectedDate = date
        selectedDate.setHours(startHours, startMinutes, 0, 0)

        // Update the selected date
        setDate(selectedDate)

        if (selectedDate > new Date() || isRecurrenceChecked) {
            setStartHourMinute(event.target.value)
            // The end cannot be less than the end 
            if (endTotal < startTotal) {
                setEndHourMinute(event.target.value)
            }
        }
    }

    const startBookingTimeManager = (event) => {
        const startTime = event.target.value.split(":")
        const startHours = parseInt(startTime[0])
        const startMinutes = parseInt(startTime[1])
        const startTotal = 60*startHours + startMinutes

        const endTime = updatedBookingEndTime.split(":")
        const endHours = parseInt(endTime[0])
        const endMinutes = parseInt(endTime[1])
        const endTotal = 60*endHours + endMinutes

        // Cannot be before now
        const selectedDate = date
        selectedDate.setHours(startHours, startMinutes, 0, 0)

        // Update the selected date
        setDate(selectedDate)
        if (selectedDate > new Date() || isRecurrenceChecked) {
            setUpdatedBookingStartTime(event.target.value)
            // The end cannot be less than the end 
            if (endTotal < startTotal) {
                setUpdatedBookingEndTime(event.target.value)
            }
        }
    }

    const endBookingTimeManager = (event) => {
        setUpdatedBookingEndTime(event.target.value)

        // The end cannot be less than the end
        const startTime = updatedBookingStartTime.split(":")
        const startHours = parseInt(startTime[0])
        const startMinutes = parseInt(startTime[1])
        const startTotal = 60*startHours + startMinutes

        const endTime = event.target.value.split(":")
        const endHours = parseInt(endTime[0])
        const endMinutes = parseInt(endTime[1])
        const endTotal = 60*endHours + endMinutes

        // The end cannot be less than the end 
        if (endTotal < startTotal) {
            setUpdatedBookingEndTime(updatedBookingStartTime)
        }    
    }
    
    const startModifyTimeManager = (event) => {
        const startTime = event.target.value.split(":")
        const startHours = parseInt(startTime[0])
        const startMinutes = parseInt(startTime[1])
        const startTotal = 60*startHours + startMinutes

        const endTime = endModifyHourMinute.split(":")
        const endHours = parseInt(endTime[0])
        const endMinutes = parseInt(endTime[1])
        const endTotal = 60*endHours + endMinutes

        // Cannot be before now
        const selectedDate = date
        selectedDate.setHours(startHours, startMinutes, 0, 0)

        // Update the selected date
        setDate(selectedDate)
        if (selectedDate > new Date() || isRecurrenceChecked) {
            setStartModifyHourMinute(event.target.value)
            // The end cannot be less than the end 
            if (endTotal < startTotal) {
                setEndModifyHourMinute(event.target.value)
            }
        }
    }

    const endModifyTimeManager = (event) => {
        setEndModifyHourMinute(event.target.value)

        // The end cannot be less than the end
        const startTime = startModifyHourMinute.split(":")
        const startHours = parseInt(startTime[0])
        const startMinutes = parseInt(startTime[1])
        const startTotal = 60*startHours + startMinutes

        const endTime = event.target.value.split(":")
        const endHours = parseInt(endTime[0])
        const endMinutes = parseInt(endTime[1])
        const endTotal = 60*endHours + endMinutes

        // The end cannot be less than the end 
        if (endTotal < startTotal) {
            setEndModifyHourMinute(startHourMinute)
        }        
    }

    const endTimeManager = (event) => {
        setEndHourMinute(event.target.value)

        // The end cannot be less than the end
        const startTime = startHourMinute.split(":")
        const startHours = parseInt(startTime[0])
        const startMinutes = parseInt(startTime[1])
        const startTotal = 60*startHours + startMinutes

        const endTime = event.target.value.split(":")
        const endHours = parseInt(endTime[0])
        const endMinutes = parseInt(endTime[1])
        const endTotal = 60*endHours + endMinutes

        // The end cannot be less than the end 
        if (endTotal < startTotal) {
            setEndHourMinute(startHourMinute)
        }
    }

    // Function for deleting an exercise
    const deleteExercise = (e, index) => {
        e.preventDefault()
        const currentExercises = []
        for (let i = 0; i < exercises.length; i++) {
            if (exercises[i].index !== index) {
                currentExercises.push(exercises[i])
            }
        }
        setExercises(currentExercises)
    }

    // Function for updating the ttile of an exercise
    const updateExerciseTitle = (e, index) => {
        const currentExercises = []
        for (let i = 0; i < exercises.length; i++) {
            const currentExercise = exercises[i]
            if (exercises[i].index === index) {
                currentExercise.exerciseTitle = e.target.value
            }
            currentExercises.push(currentExercise)
        }
        setExercises(currentExercises)
    }

    // Function for updating the sets of an exercise
    const updateExerciseSets = (e, index) => {
        const currentExercises = []
        for (let i = 0; i < exercises.length; i++) {
            const currentExercise = exercises[i]
            if (exercises[i].index === index) {
                currentExercise.sets = e.target.value
            }
            currentExercises.push(currentExercise)
        }
        setExercises(currentExercises)
    }

    // Function for updating the reps of an exercise
    const updateExerciseReps = (e, index) => {
        const currentExercises = []
        for (let i = 0; i < exercises.length; i++) {
            const currentExercise = exercises[i]
            if (exercises[i].index === index) {
                currentExercise.reps = e.target.value
            }
            currentExercises.push(currentExercise)
        }
        setExercises(currentExercises)
    }

    // Function for updating the description of an exercise
    const updateExerciseDescription = (e, index) => {
        const currentExercises = []
        for (let i = 0; i < exercises.length; i++) {
            const currentExercise = exercises[i]
            if (exercises[i].index === index) {
                currentExercise.description = e.target.value
            }
            currentExercises.push(currentExercise)
        }
        setExercises(currentExercises)
    }

    function resetExerciseErrors() {
        let currentExercises = []
        for (let i = 0; i < exercises.length; i++) {
            const currentExercise = exercises[i]
            currentExercise.titleError = ""
            currentExercise.descriptionError = ""
            currentExercise.repsError = ""
            currentExercise.setsError = ""

            currentExercises.push(currentExercise)
        }
        setExercises(currentExercises)
    }

    const addWorkoutBackend = async (userId, workoutTitle, exercises) => {
        const sortedExercises = []
        for (let i = 0; i < exercises.length; i++) {
            const exercise = exercises[i]
            const currentExercise = {
                exerciseTitle: exercise.exerciseTitle,
                sets: exercise.sets,
                reps: exercise.reps,
                description: exercise.description
            }
            sortedExercises.push(currentExercise)
        }
        const workoutInformation = {
            userId,
            workoutTitle,
            exercises: sortedExercises
        }
        axios.post(`${process.env.REACT_APP_BACKEND}/workout/add`, workoutInformation)
            .then(response => {
                const workoutId = response.data._id
                // Now add the workout to the display
                setWorkouts([...workouts, {
                    id: workoutId,
                    workoutTitle: workoutTitle,
                    exercises: exercises
                }])
            })
    }

    const addWorkout = (e) => {
        e.preventDefault()
        resetExerciseErrors()
        // First check the fields of all of the exercises to ensure they are validated
        let currentExercises = []
        let hasError = false
        for (let i = 0; i < exercises.length; i++) {
            // Add the error for each of these
            const currentExercise = exercises[i]
            const exerciseTitle = currentExercise.exerciseTitle
            const exerciseSets = currentExercise.sets
            const exerciseReps = currentExercise.reps
            const exerciseDescription = currentExercise.description

            if (exerciseTitle.length > 250) {
                currentExercise.titleError = "Maximum of 250 characters."
                hasError = true
            } else if (exerciseTitle.length === 0) {
                currentExercise.titleError = "This field is required."
                hasError = true
            }
            if (exerciseDescription.length > 1000) {
                currentExercise.descriptionError = "Maximum of 1000 characters."
                hasError = true
            }
            if (exerciseReps.toString().length === 0) {
                currentExercise.repsError = "This field is required."
                hasError = true
            } else if (exerciseReps > 100) {
                currentExercise.repsError = "Maximum of 100 repititions."
                hasError = true
            }
            if (exerciseSets.toString().length === 0) {
                currentExercise.setsError = "This field is required."
                hasError = true
            } else if (exerciseSets > 100) {
                currentExercise.setsError = "Maximum of 100 sets."
                hasError = true
            }

            currentExercises.push(currentExercise)
        }
        if (hasError) {
            // Alert the user of these errors
            setExercises(currentExercises)
        } else {
            // Add information including the workout title and the exercises
            if (exercises.length > 0) {
                // Add to backend
                addWorkoutBackend(user, workoutTitle, exercises)
                handleCloseWorkoutFormModal()
                setExercises([])
                setWorkoutTitle("")
            } else {
                setWorkoutError("Exercise Required")
            }
        }
    }

    const deleteWorkoutBackend = async (id) => {
        axios.post(`${process.env.REACT_APP_BACKEND}/workout/delete`, { workoutId: id })
    }

    const completeWorkout = (id) => {
        handleShowCongratulationsModal()
        deleteWorkout(id)
    }

    const deleteWorkout = (id) => {
        // Delete from backend
        deleteWorkoutBackend(id)
        // Delete the workout
        const currentWorkouts = []
        for (let i = 0; i < workouts.length; i++) {
            const currentWorkout = workouts[i]
            if (currentWorkout.id !== id) {
                currentWorkouts.push(currentWorkout)
            }
        }
        setWorkouts(currentWorkouts)
    }

    function addEvent(newEvent, availableArray, recurrence) {
        // Check for event overlap
        let eventOverlap = false 
        let updatedAvailableArray = []

        const newEventStart = new Date(newEvent.start)
        const newEventEnd = new Date(newEvent.end)

        availableArray.forEach(event => {
            const eventStart = new Date(event.start)
            const eventEnd = new Date(event.end)

            // Check if the new event start time is between start and end time
            const startTimeOverlap = newEventStart >= eventStart && newEventStart <= eventEnd

            // Check if the new event end time is between start and end time
            const endTimeOverlap = newEventEnd >= eventStart && newEventEnd <= eventEnd

            // Dont add the event if both overlap
            // Check if event captured event inside
            if (newEventStart <= eventStart && newEventEnd >= eventEnd) {
                // Replace the event
                const modifiedEvent = {
                    class: "available",
                    start: newEventStart,
                    end: newEventEnd,
                    recurrence: recurrence,
                    display: 'background',
                    color: "white"   
                }
                updatedAvailableArray.push(modifiedEvent)
                eventOverlap = true
            } else if (startTimeOverlap) {
                // Update the event
                const modifiedEvent = {
                    class: "available",
                    start: eventStart,
                    end: newEventEnd,
                    recurrence: recurrence,
                    display: 'background',
                    color: "white"   
                }
                updatedAvailableArray.push(modifiedEvent)
                eventOverlap = true
            } else if (endTimeOverlap) {
                // Update the event
                const modifiedEvent = {
                    class: "available",
                    start: newEventStart,
                    end: eventEnd,
                    recurrence: recurrence,
                    display: 'background',
                    color: "white"  
                }
                updatedAvailableArray.push(modifiedEvent)
                eventOverlap = true
            } else if (!startTimeOverlap && !endTimeOverlap) {
                updatedAvailableArray.push(event)
            }
        })
        
        // Add the new event if it hasn't been added already
        if (!eventOverlap) {
            updatedAvailableArray.push(newEvent)
        }

        updatedAvailableArray.sort((a, b) => {
            return new Date(a.start) - new Date(b.start);
        });   

        return { eventOverlap, updatedAvailableArray };
    }

    // Do for recurrence in the future
    async function modifyBooking() {
        const eventResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/booking/all`);
        const eventData = eventResponse.data

        
        let eventInformation = {}
        let eventId = null;
        let recurrence = false
        eventData.forEach(event => {
            const eventStart = new Date(event.bookingStartTime)
            const eventEnd = new Date(event.bookingEndTime)

            if (!event.recurrence) {
                if (eventStart.getTime() === selectedBookingStartTime.getTime() && eventEnd.getTime() === selectedBookingEndTime.getTime()) {
                    eventId = event._id
                }
            } else {
                // Different system
            }
        })

        // Check if recurrence
        if (!recurrence) {
            const startTimeArray = updatedBookingStartTime.split(":")
            const startHours = parseInt(startTimeArray[0])
            const startMinutes = parseInt(startTimeArray[1])
            const startDate = new Date(date)
            startDate.setHours(startHours, startMinutes, 0, 0)
    
            const endTime = updatedBookingEndTime.split(":")
            const endHours = parseInt(endTime[0])
            const endMinutes = parseInt(endTime[1])
            const endDate = new Date(date)
            endDate.setHours(endHours, endMinutes, 0, 0)

            // Iterate and update
            const bookingArray = []
            calendarEvents.forEach(event => {
                if (event.class === "booking") {
                    const eventStart = new Date(event.start)
                    const eventEnd = new Date(event.end)
                    if (eventStart.getTime() === selectedBookingStartTime.getTime() && eventEnd.getTime() === selectedBookingEndTime.getTime()) {
                        const formattedComments = updatedBookingComments ? "Comments: " + updatedBookingComments : ""
                        const formattedPhone = updatedBookingPhone ? "Phone: +64 " + updatedBookingPhone + (updatedBookingComments.length > 0 ? "\n" : "") : "";
                        const formattedEmail = updatedBookingEmail ? "Email: " + updatedBookingEmail + (formattedPhone.length > 0 || updatedBookingComments.length > 0 ? "\n" : "") : "";

                        const bookingEvent = {
                            class: "booking",
                            start: startDate,
                            end: endDate,
                            firstName: capitaliseFirstLetter(updatedBookingFirstName),
                            lastName: capitaliseFirstLetter(updatedBookingLastName),
                            type: updatedBookingType,
                            emailAddress: updatedBookingEmail,
                            phoneNumber: updatedBookingPhone,
                            comments: updatedBookingComments,
                            title: capitaliseFirstLetter(updatedBookingFirstName) + " " + capitaliseFirstLetter(updatedBookingLastName) + "\n" 
                            + "Type: " + formattedSessionName[updatedBookingType]
                            + (formattedEmail.length > 0 || formattedPhone.length > 0 || formattedComments.length > 0 ? "\n" : "")
                            + formattedEmail
                            + formattedPhone
                            + formattedComments
                        }

                        eventInformation = {
                            _id: eventId,
                            sessionType: updatedBookingType,
                            bookingStartTime: startDate,
                            bookingEndTime: endDate,
                            firstName: capitaliseFirstLetter(updatedBookingFirstName),
                            lastName: capitaliseFirstLetter(updatedBookingLastName),
                            emailAddress: updatedBookingEmail,
                            phoneNumber: updatedBookingPhone,
                            comments: updatedBookingComments,
                            isRecurrence: false,
                            expiresAt: endDate
                        }

                        bookingArray.push(bookingEvent)
                    } else {
                        bookingArray.push(event)
                    }
                }
            })

            // This is imperative
            bookingArray.sort((a, b) => new Date(a.start) - new Date(b.start))

            const otherArray = []
            calendarEvents.forEach(event => {
                if (event.class !== "booking") {
                    otherArray.push(event)
                }
            })

            setCalendarEvents([...otherArray, ...bookingArray])
        }
        
        handleCloseBookingFormModal()
        axios.post(`${process.env.REACT_APP_BACKEND}/booking/update`, eventInformation)
    }

    // Do for recurrence in the future
    async function modifyAvailability() {
        const eventResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/calendar/all`);
        const eventData = eventResponse.data

        let eventId = null;
        eventData.forEach(event => {
            const eventStart = new Date(event.startTime)
            const eventEnd = new Date(event.endTime)

            if (eventStart.getTime() === startAvailableTime.getTime() && eventEnd.getTime() === endAvailableTime.getTime()) {
                eventId = event._id
            }
        })

        const startTimeArray = startModifyHourMinute.split(":")
        const startHours = parseInt(startTimeArray[0])
        const startMinutes = parseInt(startTimeArray[1])
        const startDate = new Date(date)
        startDate.setHours(startHours, startMinutes, 0, 0)

        const endTime = endModifyHourMinute.split(":")
        const endHours = parseInt(endTime[0])
        const endMinutes = parseInt(endTime[1])
        const endDate = new Date(date)
        endDate.setHours(endHours, endMinutes, 0, 0)

        // IMPORTANT: CHECK FOR OVERLAP
        const eventInformation = {
            _id: eventId,
            startTime: startDate,
            endTime: endDate,
            isRecurrence: false,
            expiresAt: endDate
        }

        // Iterate and update
        const availabilityArray = []
        let lastAvailabilityTime = new Date()
        calendarEvents.forEach(event => {
            if (event.class === "available") {
                const eventStart = new Date(event.start)
                const eventEnd = new Date(event.end)
    
                if (eventStart.getTime() === startAvailableTime.getTime() && eventEnd.getTime() === endAvailableTime.getTime()) {
                    const newEvent = {
                        class: "available",
                        start: startDate,
                        end: endDate,
                        reccurence: true,
                        display: 'background',
                        color: "white"   
                    }
                    availabilityArray.push(newEvent)
                } else {
                    availabilityArray.push(event)
                }
            }
        })

        // This is imperative
        availabilityArray.sort((a, b) => new Date(a.start) - new Date(b.start))
        
        // Add the unavailability
        let unavailabilityArray = [] 
        availabilityArray.forEach(event => {
            // Only add unavailability if the start time is before the end time
            if (new Date(lastAvailabilityTime) < new Date(event.end)) {
                const unavailabilityEvent = {
                    class: "unavailable",
                    start: lastAvailabilityTime,
                    end: event.start,
                    display: "background",
                    color: "grey"
                }
                unavailabilityArray.push(unavailabilityEvent)
                // Next event starts when the last one ended
                lastAvailabilityTime = event.end
            }
        })

        // Final event
        const finalUnavailabilityEvent = {
            class: "unavailable",
            start: lastAvailabilityTime,
            end: new Date(new Date(new Date().getTime() + (193 * 86400000)).setHours(0, 0, 0, 0)),
            display: "background",
            color: "grey"            
        }
        unavailabilityArray.push(finalUnavailabilityEvent)

        const untilNowEvent = {
            class: "past",
            start: new Date(new Date().setHours(0, 0, 0, 0)),
            end: new Date(),
            display: 'background',
            color: "black"
        }

        const currentCalendarEvents = [untilNowEvent, ...unavailabilityArray, ...availabilityArray, ...getBookingEvents()]
        
        // Show the calendar
        setCalendarEvents(currentCalendarEvents)

        // This works, but need to consider recurrence 
        axios.post(`${process.env.REACT_APP_BACKEND}/calendar/update`, eventInformation)
    }

    // NOT FULLY WORKING
    async function removeAvailability() {
        const eventResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/calendar/all`);
        const eventData = eventResponse.data

        const availabilityArray = []
        let eventId = null;
        let lastAvailabilityTime = new Date()
        if (isRecurringEvent) {
            let daysArray = []
            // FIX
            eventData.forEach(event => {
                if (event.isRecurrence) {
                    const recurrence = event.recurrence
                    if (startAvailableTime.getHours() === recurrence.startHour && startAvailableTime.getMinutes() === recurrence.startMinute && 
                    endAvailableTime.getHours() === recurrence.endHour && endAvailableTime.getMinutes() === recurrence.endMinute) {
                        eventId = event._id
                        daysArray = recurrence.days
                    }   
                }
            })
            // Delete all of the ones that match
            if (isRecurringChecked) {
                // Find all with the matching time on the day
                // 27 weeks in the future (minus one day to account)
                const today = new Date()
                const targetDate = new Date(today.getTime() + 188 * 86400000) 

                // Iterate from today to the target date
                let currentDate = new Date(today)

                /*
                NEED TO ITERATE OVER
                ADD EVERYTHING THAT IS NOT IN THE WHILE LOOP
                */

                calendarEvents.forEach(event => {
                    const eventStart = new Date(event.start)
                    const eventEnd = new Date(event.end)

                    if (event.recurrence) {
                        let hasEvent = false
                        currentDate = new Date(today)
                        while (currentDate < targetDate) {
                            const currentDay = currentDate.getDay()
                            if (daysArray.includes(currentDay)) {
                                // Start time
                                const currentStartTime = new Date(currentDate)
                                currentStartTime.setHours(startAvailableTime.getHours(), startAvailableTime.getMinutes(), 0, 0)

                                // End time
                                const currentEndTime = new Date(currentDate)
                                currentEndTime.setHours(endAvailableTime.getHours(), endAvailableTime.getMinutes(), 0, 0)

                                if (currentStartTime > new Date()) {
                                    // Remove the event
                                    if (eventStart.getTime() === currentStartTime.getTime() && eventEnd.getTime() === currentEndTime.getTime()) {
                                        hasEvent = true
                                    }
                                }
                            }
                            
                            // Increment by 1 day
                            currentDate.setDate(currentDate.getDate() + 1); 
                        }

                        if (!hasEvent) {
                            availabilityArray.push(event)
                        }
                    } else if (event.class === "available") {
                        availabilityArray.push(event)
                    }
                })
            } else {
                // Add an exception
                const currentStartTime = new Date(date)
                currentStartTime.setHours(startAvailableTime.getHours(), startAvailableTime.getMinutes(), 0, 0)

                const currentEndTime = new Date(date)
                currentEndTime.setHours(endAvailableTime.getHours(), endAvailableTime.getMinutes(), 0, 0)

                calendarEvents.forEach(event => {
                    const eventStart = new Date(event.start)
                    const eventEnd = new Date(event.end)

                    // Found event
                    if (!(eventStart.getTime() === currentStartTime.getTime() && eventEnd.getTime() === currentEndTime.getTime())) {
                        if (event.class === "available") {
                            availabilityArray.push(event)
                        }
                    }
                })
            }
        } else {
            eventData.forEach(event => {
                const eventStart = new Date(event.startTime)
                const eventEnd = new Date(event.endTime)

                if (eventStart.getTime() === startAvailableTime.getTime() && eventEnd.getTime() === endAvailableTime.getTime()) {
                    eventId = event._id
                }
            })
            // FIX FIX FIX 

            // Remove the event
            calendarEvents.forEach(event => {
                const eventStartTime = new Date(event.start).getTime();
                const eventEndTime = new Date(event.end).getTime();
            
                const getStartAvailableTime = startAvailableTime.getTime();
                const getEndAvailableTime = endAvailableTime.getTime();
                if (eventStartTime < new Date()) {
                    lastAvailabilityTime = event.end
                }
                if (!(eventStartTime === getStartAvailableTime && eventEndTime === getEndAvailableTime) && event.class === "available") {
                    availabilityArray.push(event)
                }
            });
        }

        // This is imperative
        availabilityArray.sort((a, b) => new Date(a.start) - new Date(b.start))

        // Add the unavailability
        let unavailabilityArray = [] 
        availabilityArray.forEach(event => {
            // Only add unavailability if the start time is before the end time
            if (new Date(lastAvailabilityTime) < new Date(event.end)) {
                const unavailabilityEvent = {
                    class: "unavailable",
                    start: lastAvailabilityTime,
                    end: event.start,
                    display: "background",
                    color: "grey"
                }
                unavailabilityArray.push(unavailabilityEvent)
                // Next event starts when the last one ended
                lastAvailabilityTime = event.end
            }
        })

        // Final event
        const finalUnavailabilityEvent = {
            class: "unavailable",
            start: lastAvailabilityTime,
            end: new Date(new Date(new Date().getTime() + (193 * 86400000)).setHours(0, 0, 0, 0)),
            display: "background",
            color: "grey"            
        }
        unavailabilityArray.push(finalUnavailabilityEvent)

        const untilNowEvent = {
            class: "past",
            start: new Date(new Date().setHours(0, 0, 0, 0)),
            end: new Date(),
            display: 'background',
            color: "black"
        }

        const currentCalendarEvents = [untilNowEvent, ...unavailabilityArray, ...availabilityArray, ...getBookingEvents()]
        
        // Show the calendar
        setShowCalendar(true)
        setCalendarEvents(currentCalendarEvents)

        if (isRecurringEvent && !isRecurringChecked) {
            // Add an exception
            const exceptionDate = new Date(date)
            exceptionDate.setHours(startAvailableTime.getHours(), startAvailableTime.getMinutes(), 0, 0)
            axios.post(`${process.env.REACT_APP_BACKEND}/calendar/exception`, { _id: eventId, exception: exceptionDate })
        } else {
            axios.post(`${process.env.REACT_APP_BACKEND}/calendar/delete`, { _id: eventId })
        }
    }

    const manageOverrideEvent = async (newEvent) => {
        const eventResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/calendar/all`);
        const eventData = eventResponse.data

        const newEventStart = new Date(newEvent.startTime)
        const newEventEnd = new Date(newEvent.endTime)

        eventData.forEach(event => {
            const eventStart = new Date(event.startTime)
            const eventEnd = new Date(event.endTime)

            // Check if the new event start time is between start and end time
            const startTimeOverlap = newEventStart >= eventStart && newEventStart <= eventEnd

            // Check if the new event end time is between start and end time
            const endTimeOverlap = newEventEnd >= eventStart && newEventEnd <= eventEnd
            
            const eventInformation = {
                _id: event._id,
                startTime: eventStart,
                endTime: eventEnd,
                isRecurrence: false,
                expiresAt: eventEnd
            }

            let eventOverlap = false

            if (newEventStart <= eventStart && newEventEnd >= eventEnd) {
                // Replace the event
                eventInformation.startTime = newEventStart
                eventInformation.endTime = newEventEnd
                eventOverlap = true
            } else if (startTimeOverlap) {
                // Update the event
                eventInformation.startTime = eventStart
                eventInformation.endTime = newEventEnd
                eventOverlap = true
            } else if (endTimeOverlap) {
                // Update the event
                eventInformation.startTime = newEventStart
                eventInformation.endTime = eventEnd
                eventOverlap = true
            }

            if (eventOverlap) {
                axios.post(`${process.env.REACT_APP_BACKEND}/calendar/update`, eventInformation)
            }
        })
    }

    const handleAvailabilitySubmit = (event) => {
        event.preventDefault()
        // All that needs to be managed here is start/end time and recurrence
        // IMPORTANT: Ensure that the dates are in the future
        const startTimeArray = startHourMinute.split(":")
        const startHours = parseInt(startTimeArray[0])
        const startMinutes = parseInt(startTimeArray[1])
        const startDate = new Date(date)
        startDate.setHours(startHours, startMinutes, 0, 0)

        const endTime = endHourMinute.split(":")
        const endHours = parseInt(endTime[0])
        const endMinutes = parseInt(endTime[1])
        const endDate = new Date(date)
        endDate.setHours(endHours, endMinutes, 0, 0)
        
        const untilNowEvent = {
            class: "past",
            start: new Date(new Date().setHours(0, 0, 0, 0)),
            end: new Date(),
            display: 'background',
            color: "black"
        }
        let availableArray = []
        let test = []
        calendarEvents.forEach(calendarEvent => {
            if (calendarEvent.class === "available") {
                availableArray.push(calendarEvent)
                test.push(calendarEvent)
            }
        })

        if (isRecurrenceChecked) {
            // Only add recurrence if a day is selected
            if (mondaySelected || tuesdaySelected || wednesdaySelected || thursdaySelected || fridaySelected || saturdaySelected || sundaySelected) {
                // 27 weeks in the future (minus one day to account)
                const today = new Date()
                const targetDate = new Date(today.getTime() + 188 * 86400000) 

                // Iterate from today to the target date
                let currentDate = today;
                const daysArray = []
                // Add days
                if (sundaySelected) {
                    daysArray.push(0)
                }
                if (mondaySelected) {
                    daysArray.push(1)
                }
                if (tuesdaySelected) {
                    daysArray.push(2)
                }
                if (wednesdaySelected) {
                    daysArray.push(3)
                }
                if (thursdaySelected) {
                    daysArray.push(4)
                }
                if (fridaySelected) {
                    daysArray.push(5)
                }
                if (saturdaySelected) {
                    daysArray.push(6)
                }
                while (currentDate < targetDate) {
                    const currentDay = currentDate.getDay()

                    if (daysArray.includes(currentDay)) {
                        // Start time
                        const currentStartTime = new Date(currentDate)
                        currentStartTime.setHours(startHours, startMinutes, 0, 0)

                        // End time
                        const currentEndTime = new Date(currentDate)
                        currentEndTime.setHours(endHours, endMinutes, 0, 0)

                        const newEvent = {
                            class: "available",
                            start: currentStartTime,
                            end: currentEndTime,
                            recurrence: true,
                            display: 'background',
                            color: "white"
                        }
                        
                        // Ensure that the dates are in the future
                        if (currentStartTime > new Date()) {
                            const { updatedAvailableArray } = addEvent(newEvent, availableArray, true)
                            availableArray = [...updatedAvailableArray]
                        }
                    }
                    
                    // Increment by 1 day
                    currentDate.setDate(currentDate.getDate() + 1); 
                }

                const endDate = new Date(currentDate)
                endDate.setHours(endHours, endMinutes, 0, 0)

                // Add to backend
                const eventData = {
                    isRecurrence: true,
                    recurrence: {
                        startHour: startHours,
                        startMinute: startMinutes,
                        endHour: endHours,
                        endMinute: endMinutes,
                        days: daysArray,
                        exceptions: []
                    },
                    expiresAt: endDate
                }
                axios.post(`${process.env.REACT_APP_BACKEND}/calendar/add`, eventData)
            }
        } else {
            const newEvent = {
                class: "available",
                start: startDate,
                end: endDate,
                display: 'background',
                color: "white"
            }

            // Sort availableArray based on the 'startTime' property in ascending order
            availableArray.sort((a, b) => {
                return new Date(a.start) - new Date(b.start);
            });

            const { eventOverlap, updatedAvailableArray } = addEvent(newEvent, availableArray, false)

            // Last updated
            const eventData = {
                startTime: newEvent.start,
                endTime: newEvent.end,
                isRecurrence: false,
                expiresAt: newEvent.end
            }

            if (!eventOverlap) {
                // Unique event
                axios.post(`${process.env.REACT_APP_BACKEND}/calendar/add`, eventData)
            } else {
                // Modified a prexisting event (make a check for this)
                manageOverrideEvent(eventData)
            }

            availableArray = [...updatedAvailableArray]
        }

        // Add the unavailability
        let unavailabilityArray = []
        let lastAvailabilityTime = new Date() 
        // Check if the first event overrides this
        if (availableArray[0].start <= lastAvailabilityTime) {
            lastAvailabilityTime = availableArray[0].end
        }

        availableArray.forEach(event => {
            if (new Date(lastAvailabilityTime) < new Date(event.start)) {
                const unavailabilityEvent = {
                    class: "unavailable",
                    start: lastAvailabilityTime,
                    end: event.start,
                    display: "background",
                    color: "grey"
                }
                unavailabilityArray.push(unavailabilityEvent)
            }
            // Next event starts when the last one ended
            lastAvailabilityTime = event.end
        })

        // Final event
        const finalUnavailabilityEvent = {
            class: "unavailable",
            start: lastAvailabilityTime,
            end: new Date(new Date(new Date().getTime() + (193 * 86400000)).setHours(0, 0, 0, 0)),
            display: "background",
            color: "grey"            
        }
        unavailabilityArray.push(finalUnavailabilityEvent)

        const currentCalendarEvents = [untilNowEvent, ...unavailabilityArray, ...availableArray, ...getBookingEvents()]
        setCalendarEvents(currentCalendarEvents);
        handleCloseBookingModal()
    }

    const handleBookingSubmit = (event) => {
        event.preventDefault()
        // Clear errors
        clearBookingErrors()
        // All that needs to be managed here is start/end time and recurrence
        // IMPORTANT: Ensure that the dates are in the future
        const startTimeArray = startHourMinute.split(":")
        const startHours = parseInt(startTimeArray[0])
        const startMinutes = parseInt(startTimeArray[1])
        const startDate = new Date(date)
        startDate.setHours(startHours, startMinutes, 0, 0)

        const endTime = endHourMinute.split(":")
        const endHours = parseInt(endTime[0])
        const endMinutes = parseInt(endTime[1])
        const endDate = new Date(date)
        endDate.setHours(endHours, endMinutes, 0, 0)

        setModalNameError("")
        let validForm = true
        const firstName = bookingFirstName.replace(/\s+/g, "")
        const lastName = bookingLastName.replace(/\s+/g, "")
        const emailAddress = bookingEmail.replace(/\s+/g, "")
        const phoneNumber = bookingPhone.replace(/\s+/g, "")

        // Manage the email address
        const minimumEmailLength = 4
        const maximumEmailLength = 320
        /* A valid email address has:
        One @ symbol
        A dot that is after the @ symbol
        */
       if (emailAddress.length > 0) {
            if (emailAddress.length > maximumEmailLength || emailAddress.length < minimumEmailLength) {
                setBookingEmailError(`Email must be ${minimumEmailLength}-${maximumEmailLength} characters.`)
                // Set the border colour
                setBorderColour("booking-email", "red")
                validForm = false
            } else if (!(emailAddress.replace(/[^@]/g, "").length === 1 && emailAddress.split('@')[emailAddress.split('@').length - 1].includes('.'))) {
                setBookingEmailError("Invalid email address.")
                // Set the border colour
                setBorderColour("booking-email", "red")
                validForm = false 
            } else {
                // Call async function to check if the email is already taken??? Probably not needed
            }
        }

        // Manage the phone number
        const minimumPhoneLength = 8
        const maximumPhoneLength = 14
        if (phoneNumber.length > 0 && (phoneNumber.length < minimumPhoneLength || phoneNumber.length > maximumPhoneLength)) {
            setBookingPhoneError(`Phone number must be ${minimumPhoneLength}-${maximumPhoneLength} characters.`)
            // Set the border colour
            setBorderColour("signup-phone-container", "red")
            validForm = false
        }

        if (firstName.length === 0 && lastName.length === 0) {
            setModalNameError("First & last name are required.")
            setBorderColour("addCalendarFirstName", "red")
            setBorderColour("addCalendarLastName", "red")
            validForm = false
        } else if (firstName.length === 0) {
            setModalNameError("First name is required.")
            setBorderColour("addCalendarFirstName", "red")
            validForm = false
        } else if (lastName.length === 0) {
            setModalNameError("Last name is required.")
            setBorderColour("addCalendarLastName", "red")
            validForm = false
        }

        // Check if the name is too long
        const maximumNameLength = 64
        if (firstName.length > maximumNameLength && lastName.length > maximumNameLength) {
            setModalNameError("Maximum of 64 characters for first & last name.")
            setBorderColour("addCalendarFirstName", "red")
            setBorderColour("addCalendarLastName", "red")
            validForm = false
        } else if (firstName.length > maximumNameLength) {
            setModalNameError("Maximum of 64 characters for first name.")
            setBorderColour("addCalendarFirstName", "red")            
            validForm = false
        } else if (lastName.left > maximumNameLength) {
            setModalNameError("Maximum of 64 characters for last name.")
            setBorderColour("addCalendarLastName", "red")
            validForm = false
        }   

        if (validForm) {
            // Add
            let bookingType = "custom"
            if (!isCustomSession) {
                // Get the booking type and modify appropriately
                bookingType = selectedValue
                const minutesToAdd = sessionLength[bookingType]
                endDate.setTime(startDate.getTime())
                endDate.setMinutes(endDate.getMinutes() + minutesToAdd)
            }
            if (isRecurrenceChecked) {
                // Add the booking events
                // Start
                let bookingStart = new Date(startDate)
                let startHours = bookingStart.getHours()
                let startMinutes = bookingStart.getMinutes()

                // End
                let bookingEnd = new Date(endDate)
                let endHours = bookingEnd.getHours()
                let endMinutes = bookingEnd.getMinutes()
                // Only add recurrence if a day is selected
                if (mondaySelected || tuesdaySelected || wednesdaySelected || thursdaySelected || fridaySelected || saturdaySelected || sundaySelected) {
                    // 27 weeks in the future (minus one day to account)
                    const today = new Date()
                    const targetDate = new Date(today.getTime() + 188 * 86400000) 

                    let bookingEvents = []

                    // Iterate from today to the target date
                    let currentDate = today;
                    const daysArray = []
                    // Add days
                    if (sundaySelected) {
                        daysArray.push(0)
                    }
                    if (mondaySelected) {
                        daysArray.push(1)
                    }
                    if (tuesdaySelected) {
                        daysArray.push(2)
                    }
                    if (wednesdaySelected) {
                        daysArray.push(3)
                    }
                    if (thursdaySelected) {
                        daysArray.push(4)
                    }
                    if (fridaySelected) {
                        daysArray.push(5)
                    }
                    if (saturdaySelected) {
                        daysArray.push(6)
                    }
                    while (currentDate < targetDate) {
                        const currentDay = currentDate.getDay()

                        if (daysArray.includes(currentDay)) {
                            // Start time
                            const currentStartTime = new Date(currentDate)
                            currentStartTime.setHours(startHours, startMinutes, 0, 0)

                            // End time
                            const currentEndTime = new Date(currentDate)
                            currentEndTime.setHours(endHours, endMinutes, 0, 0)

                            const formattedComments = bookingComments ? "Comments: " + bookingComments : ""
                            const formattedPhone = phoneNumber ? "Phone: +64 " + phoneNumber + (bookingComments.length > 0 ? "\n" : "") : "";
                            const formattedEmail = emailAddress ? "Email: " + emailAddress + (formattedPhone.length > 0 || bookingComments.length > 0 ? "\n" : "") : "";

                            const bookingEvent = {
                                class: "booking",
                                start: currentStartTime,
                                end: currentEndTime,
                                firstName: capitaliseFirstLetter(firstName),
                                lastName: capitaliseFirstLetter(lastName),
                                type: bookingType,
                                emailAddress: emailAddress,
                                phoneNumber: phoneNumber,
                                comments: bookingComments,
                                recurrence: true,
                                title: capitaliseFirstLetter(firstName) + " " + capitaliseFirstLetter(lastName) + "\n" 
                                + "Type: " + formattedSessionName[bookingType]
                                + (formattedEmail.length > 0 || formattedPhone.length > 0 || formattedComments.length > 0 ? "\n" : "")
                                + formattedEmail
                                + formattedPhone
                                + formattedComments
                            }
                            
                            // Ensure that the dates are in the future
                            if (currentStartTime > new Date()) {
                                bookingEvents.push(bookingEvent)
                            }
                        }
                        
                        // Increment by 1 day
                        currentDate.setDate(currentDate.getDate() + 1); 
                    }

                    const endDate = new Date(currentDate)
                    endDate.setHours(endHours, endMinutes, 0, 0)

                    // Add to backend
                    const bookingData = {
                        sessionType: bookingType,
                        firstName: firstName,
                        lastName: lastName,
                        emailAddress: emailAddress,
                        phoneNumber: phoneNumber,
                        comments: bookingComments,
                        isRecurrence: true,
                        recurrence: {
                            startHour: startHours,
                            startMinute: startMinutes,
                            endHour: endHours,
                            endMinute: endMinutes,
                            days: daysArray,
                            exceptions: []
                        },
                        expiresAt: endDate
                    }

                    const currentCalendarEvents = [...bookingEvents, ...calendarEvents]
                    setCalendarEvents(currentCalendarEvents)

                    axios.post("http://localhost:4000/booking/add", bookingData)
                }

            } else {
                // Add the booking event
                const formattedComments = bookingComments ? "Comments: " + bookingComments : ""
                const formattedPhone = phoneNumber ? "Phone: +64 " + phoneNumber + (bookingComments.length > 0 ? "\n" : "") : "";
                const formattedEmail = emailAddress ? "Email: " + emailAddress + (formattedPhone.length > 0 || bookingComments.length > 0 ? "\n" : "") : "";

                const bookingEvent = {
                    class: "booking",
                    start: startDate,
                    end: endDate,
                    firstName: capitaliseFirstLetter(firstName),
                    lastName: capitaliseFirstLetter(lastName),
                    type: bookingType,
                    emailAddress: emailAddress,
                    phoneNumber: phoneNumber,
                    comments: bookingComments,
                    title: capitaliseFirstLetter(firstName) + " " + capitaliseFirstLetter(lastName) + "\n" 
                    + "Type: " + formattedSessionName[bookingType]
                    + (formattedEmail.length > 0 || formattedPhone.length > 0 || formattedComments.length > 0 ? "\n" : "")
                    + formattedEmail
                    + formattedPhone
                    + formattedComments
                }
                const currentCalendarEvents = [bookingEvent, ...calendarEvents]
                setCalendarEvents(currentCalendarEvents)

                const bookingData = {
                    sessionType: bookingType,
                    bookingStartTime: startDate,
                    bookingEndTime: endDate,
                    firstName: firstName, 
                    lastName: lastName, 
                    emailAddress: emailAddress,
                    phoneNumber: phoneNumber,
                    comments: bookingComments,
                    expiresAt: endDate
                }

                axios.post(`${process.env.REACT_APP_BACKEND}/booking/add`, bookingData)
            }

            // Close modal
            handleCloseBookingModal()

            // Clear fields
            clearBookingFields()
        }

    }

    // Edit the workout in the backend
    const editWorkoutBackend = (workoutTitle, exercises) => {
        let currentWorkouts = []
        for (let i = 0; i < workouts.length; i++) {
            let currentWorkout = workouts[i]
            if (currentWorkout.id === workoutInformation.id) {
                // Update the exercises and the title of the workout depending on the information inputted
                currentWorkout.workoutTitle = workoutTitle
                currentWorkout.exercises = exercises
            }
            currentWorkouts.push(currentWorkout)
        }
        setWorkouts(currentWorkouts)
        const editWorkoutInformation = {
            workoutId: workoutInformation.id,
            workoutTitle: workoutTitle,
            exercises: exercises
        }
        axios.post(`${process.env.REACT_APP_BACKEND}/workout/edit`, editWorkoutInformation)
        setWorkoutInformation(null)
    }

    const editWorkout = (e) => {
        e.preventDefault()
        resetExerciseErrors()
        // First check the fields of all of the exercises to ensure they are validated
        let currentExercises = []
        let hasError = false
        for (let i = 0; i < exercises.length; i++) {
            // Add the error for each of these
            const currentExercise = exercises[i]
            const exerciseTitle = currentExercise.exerciseTitle
            const exerciseSets = currentExercise.sets
            const exerciseReps = currentExercise.reps
            const exerciseDescription = currentExercise.description

            if (exerciseTitle.length > 250) {
                currentExercise.titleError = "Maximum of 250 characters."
                hasError = true
            } else if (exerciseTitle.length === 0) {
                currentExercise.titleError = "This field is required."
                hasError = true
            }
            if (exerciseDescription.length > 1000) {
                currentExercise.descriptionError = "Maximum of 1000 characters."
                hasError = true
            }
            if (exerciseReps.toString().length === 0) {
                currentExercise.repsError = "This field is required."
                hasError = true
            } else if (exerciseReps > 100) {
                currentExercise.repsError = "Maximum of 100 repititions."
                hasError = true
            }
            if (exerciseSets.toString().length === 0) {
                currentExercise.setsError = "This field is required."
                hasError = true
            } else if (exerciseSets > 100) {
                currentExercise.setsError = "Maximum of 100 sets."
                hasError = true
            }

            currentExercises.push(currentExercise)
        }
        if (hasError) {
            // Alert the user of these errors
            setExercises(currentExercises)
        } else {
            // Add information including the workout title and the exercises
            if (exercises.length > 0) {
                // Add to backend
                editWorkoutBackend(workoutTitle, exercises)
                handleCloseEditWorkoutModal()
                setExercises([])
                setWorkoutTitle("")
            } else {
                setWorkoutError("Exercise Required")
            }
        }
    }

    return (
        <div>
            {isAdministrator ? (
                <div className="profile">
                    <Modal show={isBookingModalVisible} onHide={handleCloseBookingModal} dialogClassName="booking-calendar-modal">
                        <Modal.Header closeButton>
                            {/* Default bootstrap class for full width */}
                            <Modal.Title className="w-100">Add to calendar</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="booking-calendar-modal-body">
                            <div className="booking-type-title">
                                Add Type
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" id="availabilityRadio" checked={isAvailabilityChecked} onChange={() => setIsAvailabilityChecked(true)}/>
                                <label className="form-check-label" for="availabilityRadio">
                                    <div className="time-label">Availability</div>
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" id="bookingRadio" checked={!isAvailabilityChecked} onChange={() => setIsAvailabilityChecked(false)}/>
                                <label className="form-check-label" for="bookingRadio">
                                    <div className="time-label">Booking</div>
                                </label>
                            </div>

                            {/* Recurrence */}
                            <div className="recurrence-title">
                                Manage Date & Day
                            </div>
                            <div className="form-check recurrence-check">
                                <input className="form-check-input" type="checkbox" id="recurrenceCheckbox" checked={isRecurrenceChecked} onChange={() => setIsRecurrenceChecked(!isRecurrenceChecked)}/>
                                <label className="form-check-label" for="recurrenceCheckbox">
                                    <div className="time-label">Recurrence</div>
                                </label>
                            </div>

                            {isRecurrenceChecked ? (
                                <div className="button-container">
                                    <button type="button" value={mondaySelected} onClick={() => setMondaySelected(!mondaySelected)} style={mondaySelected ? { backgroundColor: "#84B6FF" } : { backgroundColor: "#F0F0F0" }}>Mon</button>
                                    <button type="button" value={tuesdaySelected} onClick={() => setTuesdaySelected(!tuesdaySelected)} style={tuesdaySelected ? { backgroundColor: "#84B6FF" } : { backgroundColor: "#F0F0F0" }}>Tue</button>
                                    <button type="button" value={wednesdaySelected} onClick={() => setWednesdaySelected(!wednesdaySelected)} style={wednesdaySelected ? { backgroundColor: "#84B6FF" } : { backgroundColor: "#F0F0F0" }}>Wed</button>
                                    <button type="button" value={thursdaySelected} onClick={() => setThursdaySelected(!thursdaySelected)} style={thursdaySelected ? { backgroundColor: "#84B6FF" } : { backgroundColor: "#F0F0F0" }}>Thu</button>
                                    <button type="button" value={fridaySelected} onClick={() => setFridaySelected(!fridaySelected)} style={fridaySelected ? { backgroundColor: "#84B6FF" } : { backgroundColor: "#F0F0F0" }}>Fri</button>
                                    <button type="button" value={saturdaySelected} onClick={() => setSaturdaySelected(!saturdaySelected)} style={saturdaySelected ? { backgroundColor: "#84B6FF" } : { backgroundColor: "#F0F0F0" }}>Sat</button>
                                    <button type="button" className="last-button" value={sundaySelected} onClick={() => setSundaySelected(!sundaySelected)} style={sundaySelected ? { backgroundColor: "#84B6FF" } : { backgroundColor: "#F0F0F0" }}>Sun</button>
                                </div>
                            ) : (
                                <div className="calendar">
                                    <Calender onChange={onChange} value={date} tileDisabled={tileDisabled} />
                                </div>
                            )}

                            <div className="timeManager">
                                <div className="start-end-title">
                                    Manage Time
                                </div>
                                <div className="startTimeManager">
                                    <label htmlFor="startTime">Start Time</label>
                                    <input type="time" id="startTime" value={startHourMinute} onChange={startTimeManager}></input>
                                </div>
                                {isCustomSession ? (
                                <div className="endTimeManager">
                                    <label htmlFor="endTime">End Time</label>
                                    <input type="time" id="endTime" value={endHourMinute} onChange={endTimeManager}></input>
                                </div>
                                ) : null}
                            </div>

                            {isAvailabilityChecked ? (
                                // Availability
                                <form onSubmit={handleAvailabilitySubmit} className="availability-form" noValidate>
                                    <div id="addModalAvailability">
                                        <button className="modal-add" type="submit">Add Availability</button>
                                    </div>
                                </form>
                            ) : (
                                // Booking
                                // IMPORTANT: ADD A WARNING IF THE FIELDS ARE INCOMPLETE
                                <form onSubmit={handleBookingSubmit} className="booking-form" noValidate>
                                    <div className="modal-details-title">
                                        Booking Details
                                    </div>
                                    <div className="form-check custom-session-check">
                                        <input className="form-check-input" type="checkbox" id="customSessionCheckbox" checked={isCustomSession} onChange={() => setIsCustomSession(!isCustomSession)}/>
                                        <label className="form-check-label" for="customSessionCheckbox">
                                            <div className="time-label">Custom Session</div>
                                        </label>
                                    </div>
                                    {!isCustomSession ? (
                                        <div className="form-group select-session-booking" id="bookingSessionSelection">
                                            <label htmlFor="bookingSelect">Select Session</label>
                                            <div className="booking-select" id="bookingSelect">
                                                <select className="form-select" id="selectBooking" value={selectedValue}
                                                    onChange={(e) => setSelectedValue(e.target.value)}>
                                                    <option value="free30m">Free Initial Consultation (30 minutes)</option>
                                                    <option value="nutritionInitial">Initial Nutrition Consultation (1 hour - $140)</option>
                                                    <option value="nutritionFollowUp">Follow Up Nutrition Consultation (30 minutes - $75)</option>
                                                    <option value="movementAssessment">Movement Assessment (45 minutes - $120)</option>
                                                    <option value="strengthTraining30m">Strength Training (30 minutes - $75)</option>
                                                    <option value="strengthTraining45m">Strength Training (45 minutes - $95)</option>
                                                    <option value="wellbeing30m">Wellbeing (30 minutes - $75)</option>
                                                    <option value="wellbeing45m">Wellbeing (45 minutes - $95)</option>
                                                </select>
                                            </div>
                                        </div>
                                    ) : null}

                                    <div>
                                        <div className="form-group half-width-container half-width-modal">
                                            <div className="half-width-left half-width-modal-left">
                                                <label htmlFor="addCalendarFirstName">First Name</label>
                                                <input 
                                                    type="text" 
                                                    id="addCalendarFirstName" 
                                                    className="form-control" 
                                                    placeholder="Enter first name"
                                                    value={bookingFirstName} 
                                                    onChange={(e) => setBookingFirstName(e.target.value)}
                                                />
                                            </div>
                                            <div className="half-width-right half-width-modal-right">
                                                <label htmlFor="addCalendarLastName">Last Name</label>
                                                <input 
                                                    type="text" 
                                                    id="addCalendarLastName" 
                                                    className="form-control" 
                                                    placeholder="Enter last name"
                                                    value={bookingLastName} 
                                                    onChange={(e) => setBookingLastName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <span className="error-message" id="modal-name-error">{modalNameError}</span>
                                    </div>

                                    <div className="form-group booking-calendar-form">
                                        <label htmlFor="booking-email" className="form-label">Email Address <span className="not-required-label">(not required)</span></label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="booking-email" 
                                            placeholder="Enter email"
                                            value={bookingEmail}
                                            onChange={(e) => setBookingEmail(e.target.value)}
                                        />
                                        <span className="error-message" id="booking-email-error">{bookingEmailError}</span>
                                    </div>

                                    <div className="form-group booking-calendar-form">
                                        <label htmlFor="signup-phone">Phone Number <span className="not-required-label">(not required)</span></label>
                                        <div className="form-control flex-container" id="signup-phone-container">
                                            <span className="phone-number-nz">+64</span>
                                            <input 
                                                id="signup-phone" 
                                                className="form-control" 
                                                name="phone" 
                                                placeholder="Enter phone number"
                                                value={bookingPhone}
                                                onChange={handlePhoneNumberChange} 
                                            />
                                        </div>
                                        <span className="error-message" id="booking-phone-error">{bookingPhoneError}</span>
                                    </div>

                                    <div className="form-group booking-calendar-form">
                                        <label htmlFor="comments">Comments <span className="optional-text">(not required)</span></label>
                                        <textarea 
                                            id="comments" 
                                            className="form-control" 
                                            placeholder="Enter comments"
                                            name="comments" 
                                            rows="4"
                                            value={bookingComments}
                                            onChange={(e) => setBookingComments(e.target.value)}
                                        />
                                    </div>

                                    <div id="addModalBooking">
                                        <button className="modal-add" type="submit">Add Booking</button>
                                    </div>
                                </form>
                            )}
                        </Modal.Body>
                    </Modal>
                    <Modal show={isAvailabilityFormVisible} onHide={handleCloseAvailabilityFormModal} dialogClassName="availability-form-modal">
                        <Modal.Header closeButton>
                            {/* Default bootstrap class for full width */}
                            {isBookingChecked ? (
                                <Modal.Title className="w-100">Add Booking</Modal.Title>
                            ) : (
                                <Modal.Title className="w-100">Manage Availability</Modal.Title>
                            )}
                        </Modal.Header>
                        <Modal.Body className="availability-form-modal-body">
                            {/* Provide the user the ability to delete/modify */}
                            {/* NEED TO ALERT USER WHAT BOOKINGIS SELECTED */}
                            <div className="form-check booking-check">
                                <input className="form-check-input" type="checkbox" id="bookingCheckbox" checked={isBookingChecked} onChange={() => setIsBookingChecked(!isBookingChecked)}/>
                                <label className="form-check-label" for="bookingCheckbox">
                                    <div className="add-booking-label">Add Booking</div>
                                </label>
                            </div>

                            {isBookingChecked ? (
                                <div>
                                    {/* Recurrence */}
                                    <div className="recurrence-title">
                                        Manage Date & Day
                                    </div>
                                    <div className="form-check recurrence-check">
                                        <input className="form-check-input" type="checkbox" id="availabilityRadio" checked={isRecurrenceChecked} onChange={() => setIsRecurrenceChecked(!isRecurrenceChecked)}/>
                                        <label className="form-check-label" for="availabilityRadio">
                                            <div className="time-label">Recurrence</div>
                                        </label>
                                    </div>

                                    {isRecurrenceChecked ? (
                                        <div className="button-container">
                                            <button type="button" value={mondaySelected} onClick={() => setMondaySelected(!mondaySelected)} style={mondaySelected ? { backgroundColor: "#84B6FF" } : { backgroundColor: "#F0F0F0" }}>Mon</button>
                                            <button type="button" value={tuesdaySelected} onClick={() => setTuesdaySelected(!tuesdaySelected)} style={tuesdaySelected ? { backgroundColor: "#84B6FF" } : { backgroundColor: "#F0F0F0" }}>Tue</button>
                                            <button type="button" value={wednesdaySelected} onClick={() => setWednesdaySelected(!wednesdaySelected)} style={wednesdaySelected ? { backgroundColor: "#84B6FF" } : { backgroundColor: "#F0F0F0" }}>Wed</button>
                                            <button type="button" value={thursdaySelected} onClick={() => setThursdaySelected(!thursdaySelected)} style={thursdaySelected ? { backgroundColor: "#84B6FF" } : { backgroundColor: "#F0F0F0" }}>Thu</button>
                                            <button type="button" value={fridaySelected} onClick={() => setFridaySelected(!fridaySelected)} style={fridaySelected ? { backgroundColor: "#84B6FF" } : { backgroundColor: "#F0F0F0" }}>Fri</button>
                                            <button type="button" value={saturdaySelected} onClick={() => setSaturdaySelected(!saturdaySelected)} style={saturdaySelected ? { backgroundColor: "#84B6FF" } : { backgroundColor: "#F0F0F0" }}>Sat</button>
                                            <button type="button" className="last-button" value={sundaySelected} onClick={() => setSundaySelected(!sundaySelected)} style={sundaySelected ? { backgroundColor: "#84B6FF" } : { backgroundColor: "#F0F0F0" }}>Sun</button>
                                        </div>
                                    ) : (
                                        <div className="calendar">
                                            <Calender onChange={onChange} value={date} tileDisabled={tileDisabled} />
                                        </div>
                                    )}

                                    <div className="timeManager">
                                        <div className="start-end-title">
                                            Manage Time
                                        </div>
                                        <div className="startTimeManager">
                                            <label htmlFor="startTime">Start Time</label>
                                            <input type="time" id="startTime" value={startHourMinute} onChange={startTimeManager}></input>
                                        </div>
                                        {isCustomSession ? (
                                            <div className="endTimeManager">
                                                <label htmlFor="endTime">End Time</label>
                                                <input type="time" id="endTime" value={endHourMinute} onChange={endTimeManager}></input>
                                            </div>
                                        ) : null}
                                    </div>
                                    <form onSubmit={handleBookingSubmit} className="booking-form" noValidate>
                                        <div className="modal-details-title">
                                            Booking Details
                                        </div>
                                        <div className="form-check custom-session-check">
                                            <input className="form-check-input" type="checkbox" id="customSessionCheckbox" checked={isCustomSession} onChange={() => setIsCustomSession(!isCustomSession)}/>
                                            <label className="form-check-label" for="customSessionCheckbox">
                                                <div className="time-label">Custom Session</div>
                                            </label>
                                        </div>
                                        {!isCustomSession ? (
                                            <div className="form-group select-session-booking" id="bookingSessionSelection">
                                                <label htmlFor="bookingSelect">Select Session</label>
                                                <div className="booking-select" id="bookingSelect">
                                                    <select className="form-select" id="selectBooking" value={selectedValue}
                                                        onChange={(e) => setSelectedValue(e.target.value)}>
                                                        <option value="free30m">Free Initial Consultation (30 minutes)</option>
                                                        <option value="nutritionInitial">Initial Nutrition Consultation (1 hour - $140)</option>
                                                        <option value="nutritionFollowUp">Follow Up Nutrition Consultation (30 minutes - $75)</option>
                                                        <option value="movementAssessment">Movement Assessment (45 minutes - $120)</option>
                                                        <option value="strengthTraining30m">Strength Training (30 minutes - $75)</option>
                                                        <option value="strengthTraining45m">Strength Training (45 minutes - $95)</option>
                                                        <option value="wellbeing30m">Wellbeing (30 minutes - $75)</option>
                                                        <option value="wellbeing45m">Wellbeing (45 minutes - $95)</option>
                                                    </select>
                                                </div>
                                            </div>
                                        ) : null}

                                        <div>
                                            <div className="form-group half-width-container half-width-modal">
                                                <div className="half-width-left half-width-modal-left">
                                                    <label htmlFor="addCalendarFirstName">First Name</label>
                                                    <input 
                                                        type="text" 
                                                        id="addCalendarFirstName" 
                                                        className="form-control" 
                                                        placeholder="Enter first name"
                                                        value={bookingFirstName} 
                                                        onChange={(e) => setBookingFirstName(e.target.value)}
                                                    />
                                                </div>
                                                <div className="half-width-right half-width-modal-right">
                                                    <label htmlFor="addCalendarLastName">Last Name</label>
                                                    <input 
                                                        type="text" 
                                                        id="addCalendarLastName" 
                                                        className="form-control" 
                                                        placeholder="Enter last name"
                                                        value={bookingLastName} 
                                                        onChange={(e) => setBookingLastName(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <span className="error-message" id="modal-name-error">{modalNameError}</span>
                                        </div>

                                        <div className="form-group booking-calendar-form">
                                            <label htmlFor="booking-email" className="form-label">Email Address <span className="not-required-label">(not required)</span></label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                id="booking-email" 
                                                placeholder="Enter email"
                                                value={bookingEmail}
                                                onChange={(e) => setBookingEmail(e.target.value)}
                                            />
                                            <span className="error-message" id="booking-email-error">{bookingEmailError}</span>
                                        </div>

                                        <div className="form-group booking-calendar-form">
                                            <label htmlFor="signup-phone">Phone Number <span className="not-required-label">(not required)</span></label>
                                            <div className="form-control flex-container" id="signup-phone-container">
                                                <span className="phone-number-nz">+64</span>
                                                <input 
                                                    id="signup-phone" 
                                                    className="form-control" 
                                                    name="phone" 
                                                    placeholder="Enter phone number"
                                                    value={bookingPhone}
                                                    onChange={handlePhoneNumberChange} 
                                                />
                                            </div>
                                            <span className="error-message" id="booking-phone-error">{bookingPhoneError}</span>
                                        </div>

                                        <div className="form-group booking-calendar-form">
                                            <label htmlFor="comments">Comments <span className="optional-text">(not required)</span></label>
                                            <textarea 
                                                id="comments" 
                                                className="form-control" 
                                                placeholder="Enter comments"
                                                name="comments" 
                                                rows="4"
                                                value={bookingComments}
                                                onChange={(e) => setBookingComments(e.target.value)}
                                            />
                                        </div>

                                        <div id="addModalBooking">
                                            <button className="modal-add" type="submit">Add Booking</button>
                                        </div>
                                    </form>
                                </div>
                            ) : (
                                <div>
                                    <div className="modifying-booking">
                                        Managing Availability:<br></br>
                                        <div className="modifying-availability-time">
                                            {`${startAvailableTime.getHours() === 0 ? 12 : (startAvailableTime.getHours() < 13 ? startAvailableTime.getHours() : startAvailableTime.getHours() - 12)}:${startAvailableTime.getMinutes() < 10 ? "0" + startAvailableTime.getMinutes() : startAvailableTime.getMinutes()} 
                                            ${startAvailableTime.getHours() < 12 ? 'am' : 'pm'}`} to<span> </span>
                                            {`${(endAvailableTime.getHours() === 0 ? 12 : (endAvailableTime.getHours() < 13 ? endAvailableTime.getHours() : endAvailableTime.getHours() - 12))}:${endAvailableTime.getMinutes() < 10 ? "0" + endAvailableTime.getMinutes() : endAvailableTime.getMinutes()} 
                                            ${endAvailableTime.getHours() < 12 ? 'am' : 'pm'}`} on {displayDate.toDateString()}
                                        </div>
                                        {isRecurringEvent ? (
                                            <div className="manage-recurrence-modify">
                                                <div className="recurring-event">
                                                    Recurring Event
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" id="allEventRadio" checked={isRecurringChecked} onChange={() => setIsRecurringChecked(true)}/>
                                                    <label className="form-check-label" for="availabilityRadio">
                                                        <div className="time-label">Modify all recurring events</div>
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" id="singularEventRadio" checked={!isRecurringChecked} onChange={() => setIsRecurringChecked(false)}/>
                                                    <label className="form-check-label" for="bookingRadio">
                                                        <div className="time-label">Modify singular event</div>
                                                    </label>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="delete-booking">
                                        <button className="delete-booking-button" onClick={removeAvailability}>Remove Availability</button>
                                    </div>
                                    <div className="start-end-title modify-title">
                                        Modify Date & Time
                                    </div>
                                    <div className="calendar">
                                        <Calender onChange={onChange} value={date} tileDisabled={tileDisabled} />
                                    </div>
                                    <div className="timeManager modifyTimeManager">
                                        {/* Allow to also modify the date */}
                                        <div className="startTimeManager">
                                            <label htmlFor="startTime">Start Time</label>
                                            <input type="time" id="startTime" value={startModifyHourMinute} onChange={startModifyTimeManager}></input>
                                        </div>
                                        <div className="endTimeManager">
                                            <label htmlFor="endTime">End Time</label>
                                            <input type="time" id="endTime" value={endModifyHourMinute} onChange={endModifyTimeManager}></input>
                                        </div>
                                    </div>
                                    {/* Add delete button */}
                                    <button className="modal-add availability-modify" type="submit" onClick={modifyAvailability}>Modify Availability</button>
                                </div>
                            )}
                        </Modal.Body>
                    </Modal>
                    <Modal show={isBookingFormVisible} onHide={handleCloseBookingFormModal} dialogClassName="booking-form-modal">
                        <Modal.Header closeButton>
                            {/* Default bootstrap class for full width */}
                            <Modal.Title className="w-100">Manage Booking</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <span className="modifying-booking-title">Managing Booking:</span>
                            <div className="modifying-booking-time">
                                <div>
                                    {`${selectedBookingStartTime.getHours() === 0 ? 12 : (selectedBookingStartTime.getHours() < 13 ? selectedBookingStartTime.getHours() : selectedBookingStartTime.getHours() - 12)}:${selectedBookingStartTime.getMinutes() < 10 ? "0" + selectedBookingStartTime.getMinutes() : selectedBookingStartTime.getMinutes()} 
                                    ${selectedBookingStartTime.getHours() < 12 ? 'am' : 'pm'}`} to<span> </span>
                                    {`${(selectedBookingEndTime.getHours() === 0 ? 12 : (selectedBookingEndTime.getHours() < 13 ? selectedBookingEndTime.getHours() : selectedBookingEndTime.getHours() - 12))}:${selectedBookingEndTime.getMinutes() < 10 ? "0" + selectedBookingEndTime.getMinutes() : selectedBookingEndTime.getMinutes()} 
                                    ${selectedBookingStartTime.getHours() < 12 ? 'am' : 'pm'}`} on {selectedBookingStartTime.toDateString()}
                                </div>
                                <div>
                                    {selectedBookingFirstName} {selectedBookingLastName}
                                </div>
                                <div>
                                    Type: {selectedBookingType}
                                </div>
                                {selectedBookingEmail.length > 0 ? (
                                    <div>
                                        Email: {selectedBookingEmail}
                                    </div>
                                ) : null}
                                {selectedBookingPhone.length > 0 ? (
                                    <div>
                                        Phone: +64 {selectedBookingPhone}
                                    </div>
                                ) : null}
                                {selectedBookingComments.length > 0 ? (
                                    <div>
                                        Comments: {selectedBookingComments}
                                    </div>
                                ) : null}
                            </div>
                            <div>
                                {isRecurringBooking ? (
                                    <div className="manage-recurrence-modify">
                                        <div className="recurring-event">
                                            Recurring Booking
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" id="allEventRadio" checked={isRecurringChecked} onChange={() => setIsRecurringChecked(true)}/>
                                            <label className="form-check-label" for="availabilityRadio">
                                                <div className="time-label">Modify all recurring bookings</div>
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" id="singularEventRadio" checked={!isRecurringChecked} onChange={() => setIsRecurringChecked(false)}/>
                                            <label className="form-check-label" for="bookingRadio">
                                                <div className="time-label">Modify singular booking</div>
                                            </label>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="delete-booking">
                                <button className="delete-booking-button" onClick={removeAvailability}>Remove Booking</button>
                            </div>
                            <div className="start-end-title modify-title">
                                Modify Date & Time
                            </div>
                            <div className="calendar">
                                <Calender onChange={onChange} value={date} tileDisabled={tileDisabled} />
                            </div>
                            <div className="timeManager modifyTimeManager">
                                {/* Allow to also modify the date */}
                                <div className="startTimeManager">
                                    <label htmlFor="startTime">Start Time</label>
                                    <input type="time" id="startTime" value={updatedBookingStartTime} onChange={startBookingTimeManager}></input>
                                </div>
                                {isCustomBooking ? (
                                    <div className="endTimeManager">
                                        <label htmlFor="endTime">End Time</label>
                                        <input type="time" id="endTime" value={updatedBookingEndTime} onChange={endBookingTimeManager}></input>
                                    </div>
                                ) : null}
                            </div>
                            <div className="modal-details-title">
                                Booking Details
                            </div>
                            <div className="form-check custom-session-check">
                                <input className="form-check-input" type="checkbox" id="customSessionCheckbox" checked={isCustomBooking} onChange={() => setIsCustomBooking(!isCustomBooking)}/>
                                <label className="form-check-label" for="customSessionCheckbox">
                                    <div className="time-label">Custom Session</div>
                                </label>
                            </div>
                            {!isCustomBooking ? (
                                <div className="form-group select-session-booking" id="bookingSessionSelection">
                                    <label htmlFor="bookingSelect">Select Session</label>
                                    <div className="booking-select" id="bookingSelect">
                                        <select className="form-select" id="selectBooking" value={selectedBookingValue}
                                            onChange={(e) => setSelectedBookingValue(e.target.value)}>
                                            <option value="free30m">Free Initial Consultation (30 minutes)</option>
                                            <option value="nutritionInitial">Initial Nutrition Consultation (1 hour - $140)</option>
                                            <option value="nutritionFollowUp">Follow Up Nutrition Consultation (30 minutes - $75)</option>
                                            <option value="movementAssessment">Movement Assessment (45 minutes - $120)</option>
                                            <option value="strengthTraining30m">Strength Training (30 minutes - $75)</option>
                                            <option value="strengthTraining45m">Strength Training (45 minutes - $95)</option>
                                            <option value="wellbeing30m">Wellbeing (30 minutes - $75)</option>
                                            <option value="wellbeing45m">Wellbeing (45 minutes - $95)</option>
                                        </select>
                                    </div>
                                </div>
                            ) : null}
                            <div className="form-group half-width-container half-width-modal">
                                <div className="half-width-left half-width-modal-left">
                                    <label htmlFor="addCalendarFirstName">First Name</label>
                                    <input 
                                        type="text" 
                                        id="addCalendarFirstName" 
                                        className="form-control" 
                                        placeholder="Enter first name"
                                        value={updatedBookingFirstName} 
                                        onChange={(e) => setUpdatedBookingFirstName(e.target.value)}
                                    />
                                </div>
                                <div className="half-width-right half-width-modal-right">
                                    <label htmlFor="addCalendarLastName">Last Name</label>
                                    <input 
                                        type="text" 
                                        id="addCalendarLastName" 
                                        className="form-control" 
                                        placeholder="Enter last name"
                                        value={updatedBookingLastName} 
                                        onChange={(e) => setUpdatedBookingLastName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <span className="error-message" id="modal-name-error">{modalNameError}</span>

                            <div className="form-group booking-calendar-form">
                                <label htmlFor="booking-email" className="form-label">Email Address <span className="not-required-label">(not required)</span></label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="booking-email" 
                                    placeholder="Enter email"
                                    value={updatedBookingEmail}
                                    onChange={(e) => setUpdatedBookingEmail(e.target.value)}
                                />
                                <span className="error-message" id="booking-email-error">{bookingEmailError}</span>
                            </div>

                            <div className="form-group booking-calendar-form">
                                <label htmlFor="signup-phone">Phone Number <span className="not-required-label">(not required)</span></label>
                                <div className="form-control flex-container" id="signup-phone-container">
                                    <span className="phone-number-nz">+64</span>
                                    <input 
                                        id="signup-phone" 
                                        className="form-control" 
                                        name="phone" 
                                        placeholder="Enter phone number"
                                        value={updatedBookingPhone}
                                        onChange={handleBookingPhoneChange} 
                                    />
                                </div>
                                <span className="error-message" id="booking-phone-error">{bookingPhoneError}</span>
                            </div>

                            <div className="form-group booking-calendar-form">
                                <label htmlFor="comments">Comments <span className="optional-text">(not required)</span></label>
                                <textarea 
                                    id="comments" 
                                    className="form-control" 
                                    placeholder="Enter comments"
                                    name="comments" 
                                    rows="4"
                                    value={updatedBookingComments}
                                    onChange={(e) => setUpdatedBookingComments(e.target.value)}
                                />
                            </div>
                            {/* Add delete button */}
                            <button className="modal-add availability-modify" type="submit" onClick={modifyBooking}>Modify Booking</button>
                        </Modal.Body>
                    </Modal>
                    <div className="booking-calendar">
                        <FullCalendar
                            datesSet={(dateInfo) => {
                                const futureDate = new Date(new Date(new Date().getTime() + (180 * 86400000)))
                                if (dateInfo.start >= futureDate) {
                                    const nextButton = document.querySelector(".fc-next-button")
                                    nextButton.disabled = true
                                }
                                if (new Date() >= dateInfo.start) {
                                    const prevButton = document.querySelector(".fc-prev-button")
                                    prevButton.disabled = true
                                }
                            }}
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            initialView="timeGridWeek"
                            events={calendarEvents} 
                            dateClick={handleTimeSlotClick}
                            eventClick={handleEventClick}
                            firstDay={(new Date()).getDay()}
                            {...calendarOptions}
                        ></FullCalendar>
                    </div>
                    <div className="profile-header">
                        <p>Add Workouts to User</p>
                    </div>
                    <form className="contactForm" noValidate>
                        <span></span>
                        <div>
                            <label htmlFor="user-workout-users">User</label>
                            <select id="user-workout-users">
                                <option value="all">All Users</option>
                            </select>
                        </div>
                        <div className="workout-margin-top">
                            <label htmlFor="user-workout-title">Title</label>
                            <input
                                type="text"
                                id="user-workout-title"
                            />
                        </div>
                        <span></span>
                        <button className="add-exercise" onClick={(e) => e.preventDefault()}>Add Exercise</button>
                        <div className="exercise-card">
                            <div className="exercise-header">
                                <div className="exercise-title">New Exercise</div>
                                <button class="exercise-delete bi bi-trash"></button>
                            </div>
                            <div className="workout-margin-top">
                                <label htmlFor="user-exercise-title">Title</label>
                                <input
                                    type="text"
                                    id="user-exercise-title"
                                />
                            </div>
                            <div className="workout-margin-top">
                                <label htmlFor="user-exercise-sets">Sets</label>
                                <input
                                    type="number"
                                    min="1"
                                    max="1000"
                                    id="user-exercise-sets"
                                />
                            </div>
                            <span></span>
                            <div className="workout-margin-top">
                                <label htmlFor="user-exercise-repetitions">Repetitions</label>
                                <input
                                    type="number"
                                    min="1"
                                    max="1000"
                                    id="user-exercise-repetitions"
                                />
                            </div>
                            <span></span>
                            <div className="workout-margin-top">
                                <label htmlFor="user-exercise-description">Description</label>
                                <textarea
                                    id="user-exercise-description"
                                />
                            </div>
                            <span></span>
                        </div>
                        <button type="submit" className="user-add-workout">Add Workout</button>
                    </form>
                </div>
        ) : isLoggedIn ? (
            <div>
                <Modal show={isWorkoutFormVisible} onHide={handleCloseWorkoutFormModal} dialogClassName="workout-form-modal">
                    <Modal.Header closeButton>
                            {/* Default bootstrap class for full width */}
                            <Modal.Title className="w-100">Add Workout</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="contactForm" noValidate>
                            <div className="workout-margin-top">
                                <div className="workout-error">{workoutError}</div>
                                <label htmlFor="user-workout-title">Title</label>
                                <input
                                    value={workoutTitle}
                                    onChange={(e) => setWorkoutTitle(e.target.value)}
                                    type="text"
                                    id="user-workout-title"
                                />
                            </div>
                            <span></span>
                            <div>
                                <button className="add-exercise" onClick={addExercise}>Add Exercise</button>
                            </div>
                            {/* Store this as a useState list of objects representing the exercises cards */}
                            {exercises.length == 0 ? (
                                <div></div>
                            ) : (
                                exercises.map((exercise) => (
                                    <div className="exercise-card">
                                        <span></span>
                                        <div className="exercise-header">
                                            <div className="exercise-title">New Exercise</div>
                                            <button class="exercise-delete bi bi-trash" onClick={(e) => deleteExercise(e, exercise.index)}></button>
                                        </div>
                                        <span></span>
                                        <div className="workout-margin-top">
                                            <label htmlFor="user-exercise-title">Title</label>
                                            <input
                                                value={exercise.exerciseTitle}
                                                onChange={(e) => updateExerciseTitle(e, exercise.index)}
                                                type="text"
                                                id="user-exercise-title"
                                            />
                                        </div>
                                        <span className="workout-field-error">{exercise.titleError}</span>
                                        <div className="workout-margin-top">
                                            <label htmlFor="user-exercise-sets">Sets</label>
                                            <input
                                                value={exercise.sets}
                                                onChange={(e) => updateExerciseSets(e, exercise.index)}
                                                type="number"
                                                min="1"
                                                max="1000"
                                                id="user-exercise-sets"
                                            />
                                        </div>
                                        <span className="workout-field-error">{exercise.setsError}</span>
                                        <div className="workout-margin-top">
                                            <label htmlFor="user-exercise-repetitions">Repetitions</label>
                                            <input
                                                value={exercise.reps}
                                                onChange={(e) => updateExerciseReps(e, exercise.index)}
                                                type="number"
                                                min="1"
                                                max="1000"
                                                id="user-exercise-repetitions"
                                            />
                                        </div>
                                        <span className="workout-field-error">{exercise.repsError}</span>
                                        <div className="workout-margin-top">
                                            <label htmlFor="user-exercise-description">Description</label>
                                            <textarea
                                                value={exercise.description}
                                                onChange={(e) => updateExerciseDescription(e, exercise.index)}
                                                id="user-exercise-description"
                                            />
                                        </div>
                                        <span className="workout-field-error">{exercise.descriptionError}</span>
                                    </div>
                                ))
                            )}
                            <div>
                                <button type="submit" className="user-add-workout" onClick={addWorkout}>Add Workout</button>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
                <Modal show={editWorkoutVisible} onHide={handleCloseEditWorkoutModal} dialogClassName="workout-edit-modal">
                        <Modal.Header closeButton>
                            {/* Default bootstrap class for full width */}
                            <Modal.Title className="w-100">Edit Workout</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="booking-edit-modal-body">
                            <form className="contactForm" noValidate>
                                <div className="workout-margin-top">
                                    <div className="workout-error">{workoutError}</div>
                                    <label htmlFor="user-workout-title">Title</label>
                                    <input
                                        value={workoutTitle}
                                        onChange={(e) => setWorkoutTitle(e.target.value)}
                                        type="text"
                                        id="user-workout-title"
                                    />
                                </div>
                                <span></span>
                                <div>
                                    <button className="add-exercise" onClick={addExercise}>Add Exercise</button>
                                </div>
                                {/* Store this as a useState list of objects representing the exercises cards */}
                                {exercises.length == 0 ? (
                                    <div></div>
                                ) : (
                                    exercises.map((exercise) => (
                                        <div className="exercise-card">
                                            <span></span>
                                            <div className="exercise-header">
                                                <div className="exercise-title">New Exercise</div>
                                                <button class="exercise-delete bi bi-trash" onClick={(e) => deleteExercise(e, exercise.index)}></button>
                                            </div>
                                            <span></span>
                                            <div className="workout-margin-top">
                                                <label htmlFor="user-exercise-title">Title</label>
                                                <input
                                                    value={exercise.exerciseTitle}
                                                    onChange={(e) => updateExerciseTitle(e, exercise.index)}
                                                    type="text"
                                                    id="user-exercise-title"
                                                />
                                            </div>
                                            <span className="workout-field-error">{exercise.titleError}</span>
                                            <div className="workout-margin-top">
                                                <label htmlFor="user-exercise-sets">Sets</label>
                                                <input
                                                    value={exercise.sets}
                                                    onChange={(e) => updateExerciseSets(e, exercise.index)}
                                                    type="number"
                                                    min="1"
                                                    max="1000"
                                                    id="user-exercise-sets"
                                                />
                                            </div>
                                            <span className="workout-field-error">{exercise.setsError}</span>
                                            <div className="workout-margin-top">
                                                <label htmlFor="user-exercise-repetitions">Repetitions</label>
                                                <input
                                                    value={exercise.reps}
                                                    onChange={(e) => updateExerciseReps(e, exercise.index)}
                                                    type="number"
                                                    min="1"
                                                    max="1000"
                                                    id="user-exercise-repetitions"
                                                />
                                            </div>
                                            <span className="workout-field-error">{exercise.repsError}</span>
                                            <div className="workout-margin-top">
                                                <label htmlFor="user-exercise-description">Description</label>
                                                <textarea
                                                    value={exercise.description}
                                                    onChange={(e) => updateExerciseDescription(e, exercise.index)}
                                                    id="user-exercise-description"
                                                />
                                            </div>
                                            <span className="workout-field-error">{exercise.descriptionError}</span>
                                        </div>
                                    ))
                                )}
                                <div>
                                    <button type="submit" className="user-add-workout" onClick={editWorkout}>Edit Workout</button>
                                </div>
                            </form>
                        </Modal.Body>
                </Modal>
                <Modal show={isCongratulationsVisible} onHide={handleCloseCongratulationsModal} dialogClassName="congratulations-form-modal">
                    <Modal.Body className="congratulations-body">
                        <div id="congratulations-content">
                            <div class="gold-icon">🏆</div>
                            <div class="congratulations-message">
                                Congratulations on completing your workout!
                            </div>
                            <div class="congratulations-continue">
                                <button onClick={handleCloseCongratulationsModal}>KEEP IT GOING</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <div className="profile-header">
                    <p>Bookings</p>
                </div>
                <div className="booking-list">
                    {userBookings.map(booking => (
                        <div key={booking._id} className="booking-card">
                            <div className="booking-info">
                                <div className="info-item">
                                    <strong>Start Time:</strong> {formatDate(new Date(booking.bookingStartTime))}
                                </div>
                                <div className="info-item">
                                    <strong>End Time:</strong> {formatDate(new Date(booking.bookingEndTime))}
                                </div>
                                <div className="info-item">
                                    <strong>First Name:</strong> {booking.firstName}
                                </div>
                                <div className="info-item">
                                    <strong>Last Name:</strong> {booking.lastName}
                                </div>
                                <div className="info-item">
                                    <strong>Email:</strong> {booking.emailAddress}
                                </div>
                                <div className="info-item">
                                    <strong>Phone Number:</strong> {booking.phoneNumber}
                                </div>
                                <div className="info-item">
                                    <strong>Comments:</strong> {booking.comments}
                                </div>
                                <div className="delete-booking cancel-booking">
                                    <button className="delete-booking-button">Cancel Booking</button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="profile-header">
                    <p className="workouts-title">Workouts</p>
                    <button className="add-workout-button" onClick={handleShowWorkoutFormModal}>Add Workout</button>
                    <div className="workout-list">
                    {workouts.map((workout, index) => (
                        index % 3 === 0 ? (
                            <div className="workout-row" key={index}>
                            {workouts.slice(index, index + 3).map((workout, subIndex) => (
                                <div className="workout-card" key={subIndex}>
                                    <div className="workout-head">
                                        <div className="workout-title">{workout.workoutTitle}</div>
                                        <button className="edit-button" onClick={() => manageShowEditWorkoutModal(workout)}>
                                            <i className="exercise-edit bi bi-pen"></i>
                                        </button>
                                    </div>
                                    <div className="exercises-list">
                                    {workout.exercises.map((exercise, exerciseIndex) => (
                                    <div className="exercise-card" key={exerciseIndex}>
                                        <div className="exercise-title">{exercise.exerciseTitle}</div>
                                        <div className="exercise-sets">
                                            <strong>Sets:</strong> {exercise.sets}
                                        </div>
                                        <div className="exercise-reps">
                                            <strong>Reps:</strong> {exercise.reps}
                                        </div>
                                        <div className="exercise-description">
                                            {exercise.description}
                                        </div>
                                    </div>
                                    ))}
                                    </div>
                                    <div className="cancel-complete">
                                        <button className="cancel-button" onClick={() => deleteWorkout(workout.id)}>Cancel</button>
                                        <button className="complete-button" onClick={() => completeWorkout(workout.id)}>Complete</button>
                                    </div>
                                </div>
                            ))}
                            {[...Array(Math.max(0, 3 - (workouts.length - index)))].map((_, i) => (
                                <div className="dummy-event" key={i}>
                                </div>
                            ))}
                            </div>
                        ) : null
                    ))}
                    </div>  
                </div>
                {workouts.length === 0 ? (
                    <div className="no-workouts">
                        No workouts to display
                    </div>
                ) : null}
            </div>
        ) : (
            <p>Not logged in</p>
        )}
        </div>
      )
};

export default Profile;