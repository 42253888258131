import React, { useState, useEffect, useRef } from 'react'
import { Link, useLocation } from "react-router-dom"
import firstImage from "../images/IMG_0918-2.jpg"
import secondImage from "../images/IMG_0915-2.jpg"
import thirdImage from "../images/IMG_0924-2.jpg"

import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

const Home = () => {
    const [currentSlide, setCurrentSlide] = useState(1)
    const [transitionTime, setTransitionTime] = useState(1500)
    const [lastClickTime, setLastClickTime] = useState(Date.now() - 1550)
    const [clickedManually, setClickedManually] = useState(false)
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const totalSlides = 5; // Replace with the total number of slides
    const timerRef = useRef(null)

    useEffect(() => {
        const img = new Image();
        img.src = firstImage;
        img.onload = () => {
            setIsImageLoaded(true);
        };
    }, []);

    function runSlideshow() {
        clearInterval(timerRef.current);
        timerRef.current = setInterval(() => {
            // Increment the current slide index
            setLastClickTime(Date.now())
            setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides)
        }, 5000);
    }

    useEffect(() => {
        runSlideshow()

        return () => clearInterval(timerRef.current)
    }, [totalSlides]);

    function resetFirstTransitionTime(userClickedManually) {
        setTransitionTime(1500)
        // Why did it go to the next slide? Did the user click it?
        if (!userClickedManually) {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides)
            runSlideshow()
        }
    }

    function resetLastTransitionTime(userClickedManually) {
        setTransitionTime(1500)
        // Why did it go to the next slide? Did the user click it?
        if (!userClickedManually) {
            setCurrentSlide((prevSlide) => (prevSlide - 1 + totalSlides) % totalSlides)
            runSlideshow()
        }
    }

    function handleFirstSlide(userClickedManually) {
        clearInterval(timerRef.current)
        setTransitionTime(0)
        setCurrentSlide(1)
        // Display this slide for 3450ms then go to the next slide
        setTimeout(() => resetFirstTransitionTime(userClickedManually), 3450)
    }

    function handleLastSlide(userClickedManually) {
        clearInterval(timerRef.current)
        setTransitionTime(0)
        setCurrentSlide(totalSlides - 2)
        // Display this slide for 3450ms then go to the next slide
        setTimeout(() => resetLastTransitionTime(userClickedManually), 3450)
    }

    const handleChangeSlide = (index) => {
        setCurrentSlide(index);
        if (index === (totalSlides - 1)) {
            // Set the first slide to be "active" now
            document.getElementById("dot-1").classList.add("selected")
            // Now need to wait for 1550ms then go to the next slide
            setTimeout(() => handleFirstSlide(clickedManually), 1550)
        } else if (index === 0) {
            // Set the last slide to be "active" now
            document.getElementById(`dot-${totalSlides - 2}`).classList.add("selected")
            // Now need to wait for 1550ms then go to last slide
            setTimeout(() => handleLastSlide(clickedManually), 1550)
        } else if (clickedManually) {
            // Rerunning slideshow
            runSlideshow()
            setClickedManually(false)
        } else {
            setClickedManually(false)
        }
    };

    const handlePreviousSlide = () => {
        setClickedManually(true)
        setCurrentSlide((prevSlide) => (prevSlide - 1 + totalSlides) % totalSlides)
    };

    // This function is only called manually
    const handleNextSlide = () => {
        setClickedManually(true)
        setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides)
    };

    function manageStates() {
        setLastClickTime(Date.now())
        clearTimeout(handleFirstSlide)
        clearTimeout(handleLastSlide)
        clearTimeout(resetFirstTransitionTime)
        clearTimeout(resetLastTransitionTime)
        clearInterval(timerRef.current)
    }

    function clickedPreviousSlide() {
        // User can click the previous slide
        if ((Date.now() - lastClickTime) > 1550) {
            manageStates()
            setTransitionTime(1500)
            handlePreviousSlide()
        }
    }

    function clickedNextSlide() {
        // User can click the next slide
        if ((Date.now() - lastClickTime) > 1550) {
            manageStates()
            setTransitionTime(1500)
            handleNextSlide()
        }
    }

    const handleDotClick = (onClickHandler, index) => {
        // Reset transition time and slideshow before click registration
        setTransitionTime(1500)
        clearInterval(timerRef.current)
        runSlideshow()
        onClickHandler() // Invoke the onClickHandler provided by rrc
    };

    const location = useLocation();

    const navigateToCoaching = (section) => {
        if (location.pathname === '/coaching') {
            window.scrollTo(0, 0);
            document.getElementById(section).scrollIntoView();
        }
    };

    return (
        <div className="home">
            <div className="slideshow">
                <div className="carousel-container">
                    {isImageLoaded && (
                        <button className="custom-arrow custom-arrow-prev" onClick={clickedPreviousSlide}>
                            <span className="arrow-icon">{"‹"}</span>
                        </button>
                    )}
                    <Carousel
                        showThumbs={false}
                        showStatus={false}
                        selectedItem={currentSlide}
                        onChange={handleChangeSlide}
                        showArrows={false}
                        transitionTime={transitionTime}
                        renderIndicator={(onClickHandler, isSelected, index, label) => {
                            if (index === 0 || index === (totalSlides - 1)) {
                                return null
                            }
                            const className = isSelected ? "dot selected" : "dot"
                            const id = `dot-${index}`
                            return (
                                <span
                                    className={className}
                                    id={id}
                                    onClick={() => handleDotClick(onClickHandler, index)}
                                    onKeyDown={onClickHandler}
                                    value={index}
                                    key={index}
                                    role="button"
                                ></span>
                            )
                        }}
                    >
                        <div>
                            <img className="slideshowImg" src={thirdImage} alt="3" width="2048" height="1152"/>
                        </div>
                        <div>
                            <img className="slideshowImg" src={firstImage} alt="1" width="2048" height="1152"/>
                        </div>
                        <div>
                            <img className="slideshowImg" src={secondImage} alt="2" width="2048" height="1152"/>
                        </div>
                        <div>
                            <img className="slideshowImg" src={thirdImage} alt="3" width="2048" height="1152"/>
                        </div>
                        <div>
                            <img className="slideshowImg" src={firstImage} alt="1" width="2048" height="1152"/>
                        </div>
                    </Carousel>
                    {isImageLoaded && (
                        <>
                            <div id="text-overlay">
                                <h1>Do you want to stay young so you can live your life doing what you love everyday?</h1>
                                <Link
                                    className="overlay-button"
                                    role="button"
                                    aria-expanded="false"
                                    to="/coaching"
                                >
                                    Work with Jacqui
                                </Link>
                            </div>
                            <button className="custom-arrow custom-arrow-next" onClick={clickedNextSlide}>
                                <span className="arrow-icon">{"›"}</span>
                            </button>
                        </>
                    )}
                </div>
            </div>
            <div className="welcome-message">
                <h1>Welcome to Jacqui Gee's Health and Wellness</h1>
                <h2>Jacqui has been involved in health, wellness, fitness, yoga, meditation and nutrition for over 35 years. Jacqui is passionate about showing people how they can live their life doing what they love everyday.<br />
                    Are you wanting to have the energy to do the things that you love?<br />
                    Don't wait for Monday, contact Jacqui today and let her help you navigate your unique journey so you can achieve your goals and be the best version of you.</h2>
            </div>
            <div className="working-with-jacqui">
                <h1>Health & Wellness</h1>
                <div className="cards">
                    <div className="card three-card">
                        <h2>Nutrition</h2>
                        <img src={firstImage} alt="Nutrition" />
                        <p>Do you want to feel confident without your clothes on?
                            Do you want to slide into your jeans instead of squeezing into them?
                            Do you want to lose weight and never find it again?
                            With my many years of experience and knowledge I will guide and support you to reach your eating, food and nutrition goals so you can look and feel amazing.
                        </p>
                        {/* <button onClick={() => navigateToCoaching("nutrition-coaching")}>Book Nutrition</button> */}
                        <Link
                            role="button"
                            aria-expanded="false"
                            to="/coaching"
                            state={{section: "nutrition-coaching"}}
                            onClick={() => navigateToCoaching('nutrition-coaching')}
                        >
                            Book Nutrition
                        </Link>
                    </div>
                    <div className="card three-card">
                        <h2>Strength Training</h2>
                        <img src={secondImage} alt="Health & Wellness Coaching" />
                        <p>Are you time poor?  Would you like to build muscle, improve your sleep and feel great without spending hours in the gym?
                        I will show you can train effectively with correct form so you can be in and out of the gym in 30 minutes.  
                        If you don't have access to a gym I can show you how you can build strength in the comfort of your home.
                        A strong body is a strong mind. As I say to most of my clients, you are paid for your brain so look after your body, move it and feed it well.
                        </p>
                        {/* <button onClick={() => navigateToCoaching("strength-training-coaching")}>Book Strength Training</button> */}
                        <Link
                            role="button"
                            aria-expanded="false"
                            to="/coaching"
                            state={{section: "strength-training-coaching"}}
                            onClick={() => navigateToCoaching('strength-training-coaching')}
                        >
                            Book Strength Training
                        </Link>
                    </div>
                    <div className="card three-card">
                        <h2>Wellbeing</h2>
                        <img src={thirdImage} alt="Health & Wellness Coaching" />
                        <p>Choosing me as your exercise professional will help you take control of your goals and turn them into reality.
                            As your exercise professional I will help you strive to be the best that you can be through my strong communication, balanced approach and expert advice.
                            It doesn't matter where you are on your wellbeing journey, if you want to live a balanced life doing the things that you love contact me today.
                        </p>
                        {/* <button onClick={() => navigateToCoaching("wellbeing-coaching")}>Book Wellbeing</button> */}
                        <Link
                            role="button"
                            aria-expanded="false"
                            to="/coaching"
                            state={{section: "wellbeing-coaching"}}
                            onClick={() => navigateToCoaching('wellbeing-coaching')}
                        >
                            Book Wellbeing
                        </Link>
                    </div>
                </div>
            </div>
            <div className="client-stories">
                <h1>Client Stories</h1>
                <div className="cards">
                    <div className="card two-card">
                        <img src={firstImage} alt="Nutrition" />
                        <p>I've been seeing Jacqui every Saturday for almost two years and I feel great.
                            I'm 50 and want to be fit and strong so I can go hiking and be comfortably active.
                            Along with my strength we have increased my flexibility and worked on my nutrition.
                            My bones are denser, my clothes fit better and I have a much better understanding of what my diet should be if I want to have a clear and well functioning mind.
                            Jacqui is kind, supportive and understanding... But will kick my butt if I need it!</p>
                        <div className="client">
                            <p><span className="client-name">Karmyn Lyons</span></p>
                        </div>
                    </div>
                    <div className="card two-card">
                        <img src={secondImage} alt="Health & Wellness Coaching" />
                        <p>Jacqui has made a significant difference in how I train and what I eat in navigating my menopause journey.
                            The results have left me stronger and happily revisiting wardrobe items that had become uncomfortable.</p>
                        <div className="client">
                            <p><span className="client-name">Penny McDonald</span></p>
                        </div>
                    </div>
                </div>
                {/* <button id="client-stories" onClick={() => navigate("/testimonials")}>Read More Client Stories</button> */}
                <div className="client-stories-cta">
                    <Link
                        id="client-stories"
                        role="button"
                        aria-expanded="false"
                        to="/testimonials"
                    >
                        Read More Client Stories
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Home;