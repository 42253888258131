import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import logo from "../images/TreeLogo.png";
import DropdownMenu from './DropdownMenu';
//import ProfileDropdown from './ProfileDropdown'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { decodeToken } from "react-jwt";

import bcrypt from 'bcryptjs'; // Import bcryptjs

const Navbar = () => {
    const [isNavbarOpen, setIsNavbarOpen] = useState(false);
    const location = useLocation();
    const [showLoginPassword, setShowLoginPassword] = useState(false);
    const [showSignUpPassword, setShowSignUpPassword] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showSignUpModal, setShowSignUpModal] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const [administrator, setAdministrator] = useState(false)

    // States of the login form
    const [loginEmail, setLoginEmail] = useState('');
    const [loginPassword, setLoginPassword] = useState('');

    // Error states of the login form
    const [loginEmailError, setLoginEmailError] = useState('');
    const [loginPasswordError, setLoginPasswordError] = useState('');

    // States of the sign up form
    const [signUpFirstName, setSignUpFirstName] = useState('');
    const [signUpLastName, setSignUpLastName] = useState('');
    const [signUpEmail, setSignUpEmail] = useState('');
    const [signUpPhone, setSignUpPhone] = useState('');
    const [signUpPassword, setSignUpPassword] = useState('');
    const [signUpConfirmPassword, setSignUpConfirmPassword] = useState('');

    // Error states of the sign up form
    const [signUpNameError, setSignUpNameError] = useState('');
    const [signUpEmailError, setSignUpEmailError] = useState('');
    const [signUpPhoneError, setSignUpPhoneError] = useState('');
    const [signUpPasswordError, setSignUpPasswordError] = useState('');
    const [signUpConfirmPasswordError, setSignUpConfirmPasswordError] = useState('');

    // Successful state of the login form
    const [unsuccessfulLogin, setUnsuccessfulLogin] = useState('');

    // Successful state of the sign up form
    const [successfulSignUp, setSuccessfulSignUp] = useState('');

    // Manage if the dropdown is open
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isMobileDropdownOpen, setIsMobileDropdownOpen] = useState(false);

    // Refernece the dropdown
    const dropdownRef = useRef(null);

    // Function to close the dropdown when clicking outside of it
    const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    }


    useEffect(() => {
        document.addEventListener("click", handleOutsideClick);

        const userToken = localStorage.getItem('user');
    
        if (userToken) {
            // Parse the user token to get user information
            const userData = decodeToken(userToken);

            // Check and set the administrator status
            const isAdmin = userData.administrator;
            setAdministrator(isAdmin)

            // Set logged in
            setLoggedIn(true);
        } else {
            setLoggedIn(false);
        }
      }, []);

    useEffect(() => {
        // Close the navbar when the route changes
        setIsNavbarOpen(false);
    }, [location]);

    const handleNavbarToggle = () => {
        setIsNavbarOpen(!isNavbarOpen);
    };

    const handleLinkClick = () => {
        setIsNavbarOpen(false);
    };

    const handleToggleLoginPassword = () => {
        setShowLoginPassword(!showLoginPassword);
    };

    const handleToggleSignUpPassword = () => {
        setShowSignUpPassword(!showSignUpPassword);
    };

    // Function to set the border colour
    function setBorderColour(id, colour) {
        const element = document.getElementById(id) 
        if (element != null) {
            element.style.borderColor = colour
        }
    }

    // Function to clear login errors
    function clearLoginErrors() {
        // Email error
        setLoginEmailError('')
        setBorderColour("login-email", "#dee2e6")

        // Password error
        setLoginPasswordError('')
        setBorderColour("login-password", "#dee2e6")

        // Unsuccessful sign up
        setUnsuccessfulLogin('')
    }

    // Function to clear sign up errors
    function clearSignUpErrors() {
        // Name error
        setSignUpNameError('')
        setBorderColour("signup-first-name", "#dee2e6")
        setBorderColour("signup-last-name", "#dee2e6")

        // Email error
        setSignUpEmailError('')
        setBorderColour("signup-email", "#dee2e6")

        // Phone number error
        setSignUpPhoneError('')
        setBorderColour("signup-phone-container", "#dee2e6")

        // Password error
        setSignUpPasswordError('')
        setBorderColour("signup-password", "#dee2e6")

        // Confirm password error
        setSignUpConfirmPasswordError('')
        setBorderColour("signup-confirm-password", "#dee2e6")
    }

    // Function to clear sign up states
    function clearSignUpStates() {
        setSignUpFirstName('')
        setSignUpLastName('')
        setSignUpEmail('')
        setSignUpPhone('')
        setSignUpPassword('')
        setSignUpConfirmPassword('')
    }

	// Function to show the login modal
	const handleShowLoginModal = () => {
        // Close the signup modal
        handleCloseSignUpModal()
        // Deselect everything
        document.activeElement.blur();
        document.documentElement.style.overflowY = 'hidden';
        setShowLoginModal(true)
	};
  
	// Function to close the login modal
	const handleCloseLoginModal = () => {
        setShowLoginModal(false);
        document.documentElement.style.overflowY = 'auto';

        // Clear all of the errors when the login modal is closed
        clearLoginErrors()
	};

    // Function to show the sign up modal
    const handleShowSignUpModal = () => {
        // Close the login modal
        handleCloseLoginModal()
        // Deselect everything
        document.activeElement.blur();
        document.documentElement.style.overflowY = 'hidden';
        setShowSignUpModal(true)
    }

    // Function to close the sign up modal
    const handleCloseSignUpModal = () => {
        setShowSignUpModal(false);
        document.documentElement.style.overflowY = 'auto';      
        
        // Clear all of errors when the sign up modal is closed
        clearSignUpErrors()
        setSuccessfulSignUp('')
    }

    // Function for handling when the phone number input is updated
    const handlePhoneNumberChange = (e) => {
        const validatedPhoneNumber = e.target.value.replace(/[^0-9\s]/g, "")
        setSignUpPhone(validatedPhoneNumber)
    }

    const navigate = useNavigate();

    function sendHome() {
        navigate("")
    }

    function handleLogout() {
        localStorage.removeItem("user")
        setLoggedIn(false)
        setAdministrator(false)
        navigate("")
    }

    const handleLoginSubmit = async(e) => {
        // Do the HTTP post manually
        e.preventDefault()
        // Clear the login errors
        clearLoginErrors()

        let validLogin = true 
        if (loginEmail.length === 0) {
            // Set the text of the email error
            setLoginEmailError("Email address is required.")
            // Set the border colour
            setBorderColour("login-email", "red")
            validLogin = false
        }
        if (loginPassword.length === 0) {
            // Set the text of the password error
            setLoginPasswordError("Password is required.")
            // Set the border colour
            setBorderColour("login-password", "red")
            validLogin = false
        }

        // Call the backend to check if the password/email matches 
        const userResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/user/get`);
        const users = userResponse.data; // Array of user objects
        
        // Find the user with the provided email
        const foundUser = users.find(user => user.emailAddress === loginEmail);
        
        if (foundUser) {
            // User with the provided email exists, now check the password
            const isPasswordValid = await bcrypt.compare(loginPassword, foundUser.password);

            if (!isPasswordValid) {
                // Passwords don't match, access denied
                validLogin = false
                setBorderColour("login-email", "red")
                setBorderColour("login-password", "red")
            }
        } else {
            // User with the provided email not found, access denied
            validLogin = false
            setBorderColour("login-email", "red")
            setBorderColour("login-password", "red")
        }

        // Login the user
        if (!validLogin) {
            setUnsuccessfulLogin("Incorrect email or password")
        } else {
            // Set logged in and close modal
            setLoggedIn(true)
            handleCloseLoginModal()
            axios.post(`${process.env.REACT_APP_BACKEND}/user/login`, foundUser)
                .then(function (response) {
                    // save to local storage
                    localStorage.setItem("user", response.data.token)

                    // Dispatching an action to store the JWT token in Redux state
                    //dispatch({ type: "LOGIN", payload: { token: response.data.token } });
                })
        }
    }

    const signUpUser = async(firstName, lastName, emailAddress, phoneNumber, password) => {
        const userData = {
            firstName: firstName, 
            lastName: lastName,
            emailAddress: emailAddress, 
            phoneNumber: phoneNumber,
            password: password
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND}/user/add`, userData);
            // Log the newly created user object
            // Clear all states
            clearSignUpErrors()
            clearSignUpStates()
            // Alert the user
            setSuccessfulSignUp("Signed up successfully")
        } catch (error) {
            // Handle any errors that occur during the API call
        }
    }

    async function checkEmailAvailability(email) {
        // It may be better practice to HTTP post the email to check it
        const userResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/user/get`);
        const emailAddressesArray = userResponse.data.map(user => user.emailAddress)
        return emailAddressesArray.includes(email)
    }

    const handleSignUpSubmit = async (e) => {
        // Do the HTTP post manually
        e.preventDefault()
        // Clear the sign up errors
        clearSignUpErrors()
        setSuccessfulSignUp('')

        let validSignUp = true

        // Manage if the lengths of the fields are entered
        if (signUpFirstName.length === 0) {
            // Set the text of the first name error
            setSignUpNameError("First and last name are required.")
            // Set the border colour
            setBorderColour("signup-first-name", "red")
            validSignUp = false
        }
        if (signUpLastName.length === 0) {
            // Set the text of the last name error
            setSignUpNameError("First and last names are required.")
            // Set the border colour
            setBorderColour("signup-last-name", "red")
            validSignUp = false
        }
        if (signUpEmail.length === 0) {
            // Set the text of the email error
            setSignUpEmailError("Email address is required.")
            // Set the border colour
            setBorderColour("signup-email", "red")
            validSignUp = false
        }
        if (signUpPassword.length === 0) {
            // Set the text of the password error
            setSignUpPasswordError("Password is required.")
            // Set the border colour
            setBorderColour("signup-password", "red")
            validSignUp = false
        }
        if (signUpConfirmPassword.length === 0) {
            // Set the text of the confirm password error
            setSignUpConfirmPasswordError("Password confirmation is required.")
            // Set the border colour
            setBorderColour("signup-confirm-password", "red")
            validSignUp = false
        }

        // Manage the lengths of the first and last names
        const maximumNameLength = 64
        if (signUpFirstName.length > maximumNameLength && signUpLastName.length > maximumNameLength) {
            setSignUpNameError(`Names must be 1-${maximumNameLength} characters.`)
            // Set the border colour
            setBorderColour("signup-first-name", "red")
            setBorderColour("signup-last-name", "red")
            validSignUp = false
        } else if (signUpFirstName.length > maximumNameLength) {
            setSignUpNameError(`First name must be 1-${maximumNameLength} characters.`)
            setBorderColour("signup-first-name", "red")
            if (signUpLastName.length === 0) {
                setSignUpNameError(`Names must be 1-${maximumNameLength} characters.`)
            }
            validSignUp = false 
        } else if (signUpLastName.length > maximumNameLength) {
            setSignUpNameError(`Last name must be 1-${maximumNameLength} characters.`)
            setBorderColour("signup-last-name", "red")
            if (signUpFirstName.length === 0) {
                setSignUpNameError(`Names must be 1-${maximumNameLength} characters.`)
            }
            validSignUp = false 
        }

        // Manage the email address
        const minimumEmailLength = 4
        const maximumEmailLength = 128
        /* A valid email address has:
        One @ symbol
        A dot that is after the @ symbol
        */
       if (signUpEmail.length > 0) {
            if (signUpEmail.length > maximumEmailLength || signUpEmail.length < minimumEmailLength) {
                setSignUpEmailError(`Email must be ${minimumEmailLength}-${maximumEmailLength} characters.`)
                // Set the border colour
                setBorderColour("signup-email", "red")
                validSignUp = false
            } else if (!(signUpEmail.replace(/[^@]/g, "").length === 1 && signUpEmail.split('@')[signUpEmail.split('@').length - 1].includes('.'))) {
                setSignUpEmailError("Invalid email address.")
                // Set the border colour
                setBorderColour("signup-email", "red")
                validSignUp = false 
            } else {
                // Call async function to check if the email is already taken
                const isEmailTaken = await checkEmailAvailability(signUpEmail);
                if (isEmailTaken) {
                    setSignUpEmailError("Email address is already in use.")
                    // Set the border colour
                    setBorderColour("signup-email", "red")
                    validSignUp = false 
                }
            }
        }

        // Manage the phone number
        const minimumPhoneLength = 8
        const maximumPhoneLength = 14
        // Remove all spaces
        const formattedSignUpPhone = signUpPhone.replaceAll(" ", "")
        if (formattedSignUpPhone.length > 0 && (formattedSignUpPhone.length < minimumPhoneLength || formattedSignUpPhone.length > maximumPhoneLength)) {
            setSignUpPhoneError(`Phone number must be ${minimumPhoneLength}-${maximumPhoneLength} characters.`)
            // Set the border colour
            setBorderColour("signup-phone-container", "red")
            validSignUp = false
        }

        // Manage the passwords
        const minimumPasswordLength = 8
        const maximumPasswordLength = 128
        if (signUpPassword.length > 0 || signUpConfirmPassword.length > 0) {
            // Check if the passwords match and lengths are valid
            if (signUpPassword.length >= minimumPasswordLength && signUpPassword.length <= maximumPasswordLength 
                && signUpConfirmPassword.length > 0 && signUpPassword !== signUpConfirmPassword) {
                const passwordError = `Passwords do not match.`
                setSignUpPasswordError(passwordError)
                setSignUpConfirmPasswordError(passwordError)
                // Set the border colours
                setBorderColour("signup-password", "red")
                setBorderColour("signup-confirm-password", "red")
                validSignUp = false
            } else if (signUpPassword.length > maximumPasswordLength || signUpPassword.length < minimumPasswordLength) {
                const passwordError = `Password must be ${minimumPasswordLength}-${maximumPasswordLength} characters.`
                setSignUpPasswordError(passwordError)
                setSignUpConfirmPasswordError(passwordError)
                // Set the border colours
                setBorderColour("signup-password", "red")
                setBorderColour("signup-confirm-password", "red")
                validSignUp = false
            }
        }

        // Valid sign up, send the data
        if (validSignUp) {
            signUpUser(signUpFirstName, signUpLastName, signUpEmail, signUpPhone, signUpPassword)
        }
    }

    return (
        <header>
            <nav className="navbar navbar-expand-custom">
                <div id="placeholder">
                    <button className="navbar-toggler" onClick={handleNavbarToggle}><span className="navbar-toggler-icon"></span></button>
                </div>
                <img id="logo" src={logo} onClick={sendHome} alt="Tree of Life logo" width="635" height="635"/>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={handleNavbarToggle}
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className={`collapse navbar-collapse ${isNavbarOpen ? 'show' : ''}`} id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className="nav-link" to="/" onClick={handleLinkClick}>
                                Home
                            </Link>
                        </li>

                        {/* This is the coaching dropdown */}
                        <DropdownMenu onClick={handleLinkClick} />


                        <li className="nav-item">
                            <Link className="nav-link" to="/testimonials" onClick={handleLinkClick}>
                                Testimonials
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/blog" onClick={handleLinkClick}>
                                Blog
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/contact" onClick={handleLinkClick}>
                                Contact
                            </Link>
                        </li>
                        <li className="nav-item" id="login-small">
                            {!loggedIn ? (
                                <button className="nav-link" onClick={handleShowLoginModal}>
                                    Login
                                </button>
                            ) : !administrator ? (
                                <li className="nav-item dropdown no-margin">
                                    <span
                                        className="nav-link dropdown-toggle"
                                        role="button"
                                        aria-expanded="false"
                                        onClick={() => setIsMobileDropdownOpen(!isMobileDropdownOpen)}
                                    >
                                        Profile
                                    </span>
                                    <ul className={`dropdown-menu ${isMobileDropdownOpen ? ' show' : ''}`} id="dropdown-menu">
                                        <li>
                                            <Link
                                                to="/profile"
                                                className="dropdown-item"
                                            >
                                                Profile
                                            </Link>
                                        </li>
                                        <li>
                                            <button className="dropdown-item" onClick={handleLogout}>
                                                Logout 
                                            </button>
                                        </li>
                                    </ul>
                                </li>
                            ) : (
                                <li className="nav-item dropdown no-margin">
                                    <span
                                        className="nav-link dropdown-toggle"
                                        role="button"
                                        aria-expanded="false"
                                        onClick={() => setIsMobileDropdownOpen(!isMobileDropdownOpen)}
                                    >
                                        Profile
                                    </span>
                                    <ul className={`dropdown-menu ${isMobileDropdownOpen ? ' show' : ''}`} id="dropdown-menu">
                                        <li>
                                            <Link
                                                to="/profile"
                                                className="dropdown-item"
                                            >
                                                Profile
                                            </Link>
                                        </li>
                                        <li>
                                            <button className="dropdown-item" onClick={handleLogout}>
                                                Logout 
                                            </button>
                                        </li>
                                    </ul>
                                </li>
                            )}                       
                        </li>
                    </ul>
                </div>
                <ul className={`navbar-nav ml-auto ${isNavbarOpen ? 'show' : ''}`} id="login-large">
                    <li className="nav-item">
                        {!loggedIn ? (
                            <button className="nav-link" onClick={handleShowLoginModal}>
                                Login
                            </button>
                        ) : !administrator ? (
                            <li
                            className="nav-item dropdown"
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                            ref={dropdownRef}
                            >
                                <div className="profile-logo">
                                    <button>
                                        <i className="bi bi-person"></i>
                                    </button>
                                </div>
                                <ul className={`dropdown-menu dropdown-menu-start${isDropdownOpen ? ' show' : ''}`} id="dropdown-menu">
                                    <li>
                                        <Link
                                            to="/profile"
                                            className="dropdown-item"
                                        >
                                            Profile
                                        </Link>
                                    </li>
                                    <li>
                                        <button className="dropdown-item" onClick={handleLogout}>
                                            Logout 
                                        </button>
                                    </li>
                                </ul>
                            </li>
                        ) : (
                            <li
                            className="nav-item dropdown"
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                            ref={dropdownRef}
                            >
                                <div className="profile-logo">
                                    <button>
                                        <i className="bi bi-person"></i>
                                    </button>
                                </div>
                                <ul className={`dropdown-menu dropdown-menu-start${isDropdownOpen ? ' show' : ''}`} id="dropdown-menu">
                                    <li>
                                        <Link
                                            to="/profile"
                                            className="dropdown-item"
                                        >
                                            Profile
                                        </Link>
                                    </li>
                                    <li>
                                        <button className="dropdown-item" onClick={handleLogout}>
                                            Logout 
                                        </button>
                                    </li>
                                </ul>
                            </li>
                        )}     
                    </li>
                </ul>
            </nav>
            
            <Modal show={showLoginModal} onHide={handleCloseLoginModal} dialogClassName="login-modal">
                <Modal.Header closeButton>
                    {/* Default bootstrap class for full width */}
                    <Modal.Title className="w-100">Login</Modal.Title>
                </Modal.Header>
                <Modal.Body className="login-modal-body">
                    <form onSubmit={handleLoginSubmit}>
                        <span className="unsuccessful-message" id="login-unsuccessful">{unsuccessfulLogin}</span>
                        <div>
                            <label htmlFor="login-email" className="form-label">Email Address</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="login-email" 
                                placeholder="Enter email" 
                                value={loginEmail}
                                onChange={(e) => setLoginEmail(e.target.value)}
                            />
                            <span className="error-message" id="login-email-error">{loginEmailError}</span>
                        </div>
                        <div>
                            <label htmlFor="login-password" className="form-label">Password</label>
                            <input
                                type={showLoginPassword ? 'text' : 'password'}
                                className="form-control"
                                id="login-password"
                                placeholder="Password"
                                value={loginPassword}
                                autocomplete="current-password"
                                onChange={(e) => setLoginPassword(e.target.value)}
                            />
                            <span className="error-message" id="login-password-error">{loginPasswordError}</span>
                            <div className="show-password-information">
                                <input type="checkbox" className="show-password" checked={showLoginPassword} onChange={handleToggleLoginPassword}/>Show Password
                            </div>
                        </div>
                        <div className="text-center">
                            <Button type="submit" className="login-button">Login</Button>
                        </div>
                    </form>
                    <div className="text-center forgot-password-information">
                        <a className="forgot-password-link">Forgot your password?</a>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="sign-up-information">
                        <span className="sign-up-text">Don't have an account?</span>
                        <span className="space"> </span>
                        <a className="sign-up-link" onClick={handleShowSignUpModal}>Sign up</a>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal show={showSignUpModal} onHide={handleCloseSignUpModal} dialogClassName="signup-modal">
                <Modal.Header closeButton>
                    {/* Default bootstrap class for full width */}
                    <Modal.Title className="w-100">Sign Up</Modal.Title>
                </Modal.Header>
                <Modal.Body className="signup-modal-body">
                    <form onSubmit={handleSignUpSubmit}>
                        <span className="successful-message" id="signup-successful">{successfulSignUp}</span>
                        <div className="row">
                            <div className="col-md-6">
                                <label htmlFor="signup-first-name" className="form-label">First Name</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="signup-first-name" 
                                    placeholder="First name"
                                    value={signUpFirstName}
                                    onChange={(e) => setSignUpFirstName(e.target.value)} 
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="signup-last-name" className="form-label">Last Name</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="signup-last-name" 
                                    placeholder="Last name" 
                                    value={signUpLastName}
                                    onChange={(e) => setSignUpLastName(e.target.value)}
                                />
                            </div>
                        </div>
                        <span className="error-message" id="signup-name-error">{signUpNameError}</span>
                        <div>
                            <label htmlFor="signup-email" className="form-label">Email Address</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="signup-email" 
                                placeholder="Enter email"
                                value={signUpEmail}
                                onChange={(e) => setSignUpEmail(e.target.value)}
                            />
                            <span className="error-message" id="signup-email-error">{signUpEmailError}</span>
                        </div>
                        <div className="form-group">
                            <label htmlFor="signup-phone">Phone Number <span className="not-required-label">(not required)</span></label>
                            <div className="form-control flex-container" id="signup-phone-container">
                                <span className="phone-number-nz">+64</span>
                                <input 
                                    id="signup-phone" 
                                    className="form-control" 
                                    name="phone" 
                                    placeholder="Enter phone number"
                                    value={signUpPhone}
                                    onChange={handlePhoneNumberChange} 
                                />
                            </div>
                            <span className="error-message" id="signup-phone-error">{signUpPhoneError}</span>
                        </div>
                        <div>
                            <label htmlFor="signup-password" className="form-label">Password</label>
                            <input
                                type={showSignUpPassword ? 'text' : 'password'}
                                className="form-control"
                                id="signup-password"
                                placeholder="Enter password"
                                value={signUpPassword}
                                onChange={(e) => setSignUpPassword(e.target.value)}
                            />
                            <span className="error-message" id="signup-password-error">{signUpPasswordError}</span>
                        </div>
                        <div>
                            <label htmlFor="signup-confirm-password" className="form-label">Confirm Password</label>
                            <input
                                type={showSignUpPassword ? 'text' : 'password'}
                                className="form-control"
                                id="signup-confirm-password"
                                placeholder="Enter password again"
                                value={signUpConfirmPassword}
                                onChange={(e) => setSignUpConfirmPassword(e.target.value)}
                            />
                            <span className="error-message" id="signup-confirm-password-error">{signUpConfirmPasswordError}</span>
                            <div className="show-password-information">
                                <input type="checkbox" className="show-password" checked={showSignUpPassword} onChange={handleToggleSignUpPassword}/>Show Password
                            </div>
                        </div>
                        <div className="text-center">
                            <Button type="submit" className="signup-button">Sign Up</Button>
                        </div>
                    </form>
                    </Modal.Body>
                    <Modal.Footer>
                    <div className="sign-up-information">
                        <span className="sign-up-text">Already have an account?</span>
                        <span className="space"> </span>
                        <a className="sign-up-link" onClick={handleShowLoginModal}>Login</a>
                    </div>
                </Modal.Footer>
            </Modal>
        </header>
    );
};

export default Navbar;
