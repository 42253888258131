import Calendar from "react-calendar";
import axios from 'axios';
import 'react-calendar/dist/Calendar.css';
import { useState, useEffect, useRef } from 'react';
import { useLocation } from "react-router-dom";
import { isBefore, startOfDay, getMonth, getYear, addDays, addMinutes } from 'date-fns';
import { decodeToken } from "react-jwt";

const Booking = () => {
    const [date, setDate] = useState(addDays(new Date(), 1))
    const [month, setMonth] = useState(getMonth(new Date()))
    const [year, setYear] = useState(getYear(new Date()))
    const [direction, setDirection] = useState(1) // 1 for forward, -1 for backward
    const [totalMonths, setTotalMonths] = useState(0)
    const [selectedValue, setSelectedValue] = useState("free30m")

    // Errors to manage on form submission
    const [dateError, setDateError] = useState("")
    const [firstNameError, setFirstNameError] = useState("")
    const [lastNameError, setLastNameError] = useState("")
    const [emailError, setEmailError] = useState("")
    const [phoneError, setPhoneError] = useState("")
    const [bookingTimes, setBookingTimes] = useState([])
    const [availableTimes, setAvailableTimes] = useState([])
    const [loading, setLoading] = useState(true);

    // Selected values
    const [selectedTime, setSelectedTime] = useState(null)
    const firstTime = useRef(null)
    const [bookingFirstName, setBookingFirstName] = useState("")
    const [bookingLastName, setBookingLastName] = useState("")
    const [bookingEmailAddress, setBookingEmailAddress] = useState("")
    const [bookingPhoneNumber, setBookingPhoneNumber] = useState("")
    const [bookingComments, setBookingComments] = useState("")

    const sessionLength = {
        "free30m": 30,
        "nutritionInitial": 60,
        "nutritionFollowUp": 30,
        "movementAssessment": 45,
        "strengthTraining30m": 30,
        "strengthTraining45m": 45,
        "wellbeing30m": 30,
        "wellbeing45m": 45
    }


    const location = useLocation();
    useEffect(() => {
        const selectedSession = location.state
        if (selectedSession != null) {
            setSelectedValue(selectedSession.session)
        }
    }, [])

    // Function to set the border colour
    function setBorderColour(id, colour) {
        const element = document.getElementById(id) 
        if (element != null) {
            element.style.borderColor = colour
        }
    }

    // This is a recursive function which is called until the button selector is added to both of the buttons
    function getButtons() {
        const prevButton = document.querySelector(
            '.react-calendar__navigation__prev-button'
        );
        const nextButton = document.querySelector(
            '.react-calendar__navigation__next-button'
        );

        prevButton.addEventListener('click', handlePrevButtonClick);
        nextButton.addEventListener('click', handleNextButtonClick);
        if (prevButton === null || nextButton === null) {
            setTimeout(getButtons, 50);
        } else {
            disablePrevButtons();
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const timeResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/calendar/all`);
                const timeData = timeResponse.data
                // MANAGE RECURRENCE
                timeData.sort((a, b) => new Date(a.startTime) - new Date(b.startTime))

                // Create a new array with a dictionary containing startTime and endTime
                const availability = timeData.map(item => ({
                    startTime: item.startTime,
                    endTime: item.endTime
                }))

                //setAvailableTimes(timeData)
                const bookingResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/booking/all`);
                const bookingData = bookingResponse.data
                bookingData.sort((a, b) => new Date(a.bookingStartTime) - new Date(b.bookingStartTime))

                // Create a new array with a dictionary containing startTime and endTime
                const bookings = bookingData.map(item => ({
                    startTime: item.bookingStartTime,
                    endTime: item.bookingEndTime
                }))

                const availableTimes = [];
              
                // Iterate through availability and bookings
                availability.forEach(availabilitySlot => {
                    // Check if this availability slot overlaps with any booking
                    const overlappingBookings = bookings.filter(booking =>
                        new Date(availabilitySlot.endTime) > new Date(booking.startTime) &&
                        new Date(availabilitySlot.startTime) < new Date(booking.endTime)
                    );
              
                    // If there are overlapping bookings, split the availability slot
                    if (overlappingBookings.length > 0) {
                        overlappingBookings.forEach(booking => {
                            if (new Date(availabilitySlot.startTime) < new Date(booking.startTime)) {
                                availableTimes.push({
                                startTime: availabilitySlot.startTime,
                                endTime: booking.startTime
                                });
                            }
                            if (new Date(availabilitySlot.endTime) > new Date(booking.endTime)) {
                                availabilitySlot.startTime = booking.endTime;
                            }
                        });
                    }
              
                    // If no overlap or after splitting, add the remaining availability slot
                    if (new Date(availabilitySlot.startTime) < new Date(availabilitySlot.endTime)) {
                        availableTimes.push({
                            startTime: availabilitySlot.startTime,
                            endTime: availabilitySlot.endTime
                        });
                    }
                });

                setAvailableTimes(availableTimes)


                setBookingTimes(bookingResponse.data);
                setLoading(false); // Set loading to false when data is fetched
                setTimeout(getButtons, 50);

                // Remove the event handlers on component unmount
                return () => {
                    // Get the previous and next buttons using querySelector
                    const prevButton = document.querySelector(
                        '.react-calendar__navigation__prev-button'
                    );
                    const nextButton = document.querySelector(
                        '.react-calendar__navigation__next-button'
                    );
                    prevButton.removeEventListener('click', handlePrevButtonClick);
                    nextButton.removeEventListener('click', handleNextButtonClick);
                };
            } catch (error) {
            }
        }
        fetchData()
        const currentMonth = getMonth(new Date())
        const nextMonth = getMonth(addDays(new Date(), 1))
        if (nextMonth === 0) {
            if (currentMonth === 11) {
                setTotalMonths(1)
            }
        } else {
            setTotalMonths(nextMonth - currentMonth)
        }
    }, []);

    // Capitalise first letter of a string
    function capitaliseFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    const handlePrevButtonClick = () => {
        setDirection(-1);
        setTotalMonths((totalMonths) => (totalMonths - 1))
        setMonth((prevMonth) => (prevMonth > 0 ? prevMonth - 1 : 11));
    };

    const handleNextButtonClick = () => {
        setDirection(1);
        setTotalMonths((totalMonths) => (totalMonths + 1))
        setMonth((prevMonth) => (prevMonth < 11 ? prevMonth + 1 : 0));
    };

    useEffect(() => {
        if (direction === 1 && month === 0) {
            setYear((prevYear) => prevYear + 1);
        } else if (direction === -1 && month === 11) {
            setYear((prevYear) => prevYear - 1);
        } else {
            disablePrevButtons();
        }
    }, [month, direction]);

    useEffect(() => {
        disablePrevButtons();
    }, [year])

    useEffect(() => {
        const nextButtons = document.querySelectorAll(
            '.react-calendar__navigation__next-button'
        );
        nextButtons.forEach((button) => {
            if (totalMonths >= 6) {
                button.style.pointerEvents = "none"
                button.style.backgroundColor = "#f0f0f0"
            } else {
                button.style.pointerEvents = "auto"
                button.style.backgroundColor = "#fff"
            }
        })
    }, [totalMonths])

    const onChange = (e) => {
        // Select date
        if (date.getMonth() != e.getMonth()) {
            // Clicked to next month
            handleNextButtonClick()
        }
        setDate(e)
        disablePrevButtons();
        // Manage the avaliable TIMES!
    }

    const handlePhoneNumberChange = (e) => {
        const validatedPhoneNumber = e.target.value.replace(/[^0-9\s]/g, "")
        setBookingPhoneNumber(validatedPhoneNumber)
    }

    // Improve
    const handleSubmit = async (event) => {
        event.preventDefault();
        let validForm = true
        let bookingSelectedTime = selectedTime ? new Date(selectedTime) : new Date(firstTime.current)

        // Get the values from the form data
        const selectedSession = selectedValue;

        const firstName = capitaliseFirstLetter(bookingFirstName).replace(/\s+/g, "")
        const lastName = capitaliseFirstLetter(bookingLastName).replace(/\s+/g, "")
        const emailAddress = bookingEmailAddress.replace(/\s+/g, "")
        const phoneNumber = bookingPhoneNumber.replace(/\s+/g, "")
        const comments = bookingComments
        // How long is the session length?

        // Error messages
        const fieldRequiredError = "This field is required"
        const maximumCharactersError = (characters) => `Maximum of ${characters} characters`
        // Validation for first name field
        // Regex for replacing white space with a single space
        const firstNameLength = firstName.length
        if (firstNameLength > 64) {
            setFirstNameError(maximumCharactersError(64))
            validForm = false
        } else if (firstNameLength > 0) {
            setFirstNameError("")
        } else {
            setFirstNameError(fieldRequiredError)
            validForm = false
        }
        // Validation for last name field
        const lastNameLength = lastName.length
        if (lastNameLength > 64) {
            setLastNameError(maximumCharactersError(64))
            validForm = false
        } else if (lastNameLength > 0) {
            setLastNameError("")
        } else {
            setLastNameError(fieldRequiredError)
            validForm = false
        }
        // Validation for email address
        const emailAddressLength = emailAddress.length
        if (emailAddressLength > 320) {
            setEmailError(maximumCharactersError(320))
            validForm = false
        } else if (emailAddressLength > 0) {
            // Validate the email
            // Make sure the email is in the format name@domain.extension 
            if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(emailAddress)) {
                setEmailError("")
            } else {
                setEmailError("Invalid email address")
                validForm = false
            }
        } else {
            setEmailError(fieldRequiredError)
            validForm = false
        }
        // Validation for phone number
        const phoneNumberLength = phoneNumber.length
        if (phoneNumberLength > 15) {
            setPhoneError(maximumCharactersError(15))
            validForm = false
        } else if (phoneNumberLength > 0) {
            // Double failsafe
            if (/[^0-9]/g.test()) {
                setPhoneError("")
            } else {
                setPhoneError("Invalid phone number")
                validForm = false
            }
        } else {
            setPhoneError(fieldRequiredError)
            validForm = false
        }

        // In the past
        if (bookingSelectedTime < new Date()) {
            validForm = false
        }

        const selectedBookingEndTime = new Date(bookingSelectedTime.getTime() + sessionLength[selectedSession] * 60 * 1000)
        if (validForm) {
            // Manage the validated data
            let userId = null
            const userToken = localStorage.getItem('user');
    
            if (userToken) {
                // Parse the user token to get user information
                const userData = decodeToken(userToken);
                userId = userData._id
            }

            const formData = {
                userId: userId,
                sessionType: selectedSession,
                bookingStartTime: bookingSelectedTime,
                bookingEndTime: selectedBookingEndTime,
                firstName: firstName,
                lastName: lastName,
                emailAddress: emailAddress,
                phoneNumber: phoneNumber,
                comments: comments,
                isRecurrence: false
            }

            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND}/booking/add`, formData);
                // Clear the values
                setBookingFirstName("")
                setBookingLastName("")
                setBookingEmailAddress("")
                setBookingPhoneNumber("")
                setBookingComments("")
                // Log the newly created booking object
                // Redirect to the confirmation page and send an email
            } catch (error) {
                // Handle any errors that occur during the API call
            }
        }
    }

    const disablePrevButtons = () => {
        const prevButtons = document.querySelectorAll(
            '.react-calendar__navigation__prev-button'
        );
        prevButtons.forEach((button) => {
            if (month <= getMonth(new Date()) && year === getYear(new Date())) {
                button.style.pointerEvents = "none"
                button.style.backgroundColor = "#f0f0f0"
            } else {
                button.style.pointerEvents = "auto"
                button.style.backgroundColor = "#fff"
            }
        })
    }

    const tileDisabled = ({ date, view }) => {
        if (view === "month") {
            const currentDate = new Date()
            const monthsDifference = (getYear(date) - getYear(currentDate)) * 12 + (getMonth(date) - getMonth(currentDate));
            return isBefore(startOfDay(date), currentDate) || monthsDifference > 6;
        }
        return false;
    }

    function formatTime(hour, minute) {
        return hour + ":" + ((minute >= 10) ? minute : "0" + minute) + (hour >= 12 ? "pm" : "am")
    }

    return (
        <form onSubmit={handleSubmit} className="booking-form" noValidate>
            <h1>Book with Jacqui</h1>
            <div className="form-group full-width" id="sessionSelection">
                <label htmlFor="bookingSelect">Select Session</label>
                <div className="booking-select" id="bookingSelect">
                    <select className="form-select" id="selectBooking" value={selectedValue}
                        onChange={(e) => setSelectedValue(e.target.value)}>
                        <option value="free30m">Free Initial Consultation (30 minutes)</option>
                        <option value="nutritionInitial">Initial Nutrition Consultation (1 hour - $140)</option>
                        <option value="nutritionFollowUp">Follow Up Nutrition Consultation (30 minutes - $75)</option>
                        <option value="movementAssessment">Movement Assessment (45 minutes - $120)</option>
                        <option value="strengthTraining30m">Strength Training (30 minutes - $75)</option>
                        <option value="strengthTraining45m">Strength Training (45 minutes - $95)</option>
                        <option value="wellbeing30m">Wellbeing (30 minutes - $75)</option>
                        <option value="wellbeing45m">Wellbeing (45 minutes - $95)</option>
                    </select>
                </div>
            </div>
            <div className="no-available">
                <p>For other times, please contact Jacqui at jacqui@healthfitcollective.co.nz</p>
            </div>
            {loading ? null : (
                <div>
                    <div className="calendar">
                        {/* Need to set the default value... */}
                        <Calendar onChange={onChange} value={date} tileDisabled={tileDisabled} />
                    </div>
                    <div>
                        {(() => {
                            let currentFirstTime = null
                            let timeSlotsJSX = null;
                            let currentTimeSlots = [];
                            const currentDate = new Date()

                            availableTimes.forEach((available, index) => {
                                const startTime = new Date(available.startTime);
                                const endTime = new Date(available.endTime);
                                // Display if applicable
                                if (currentTimeSlots.length > 0 && (currentDate.getFullYear() != startTime.getFullYear() || currentDate.getMonth() != startTime.getMonth() || currentDate.getDate() != startTime.getDate())) {
                                    currentFirstTime = currentTimeSlots[0]
                                    timeSlotsJSX = (
                                        <div className="form-group full-width select-booking-time">
                                            <label htmlFor="bookingSelect">Select Time</label>
                                            <div className="booking-select">
                                                <select className="form-select" value={selectedTime} onChange={(e) => setSelectedTime(e.target.value)}>
                                                    {currentTimeSlots.map((timeSlot, index) => (
                                                        <option key={timeSlot} value={timeSlot}>{`${timeSlot.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }).toLowerCase()}`}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    );
                                    // Need to somehow set the default time
                                    currentTimeSlots = []
                                }
                                
                                if (startTime.getDate() === date.getDate() && startTime.getMonth() === date.getMonth()) {
                                    let currentTime = startTime;

                                    // This also should depend on the current selected session
                                    while (new Date(currentTime.getTime() + (sessionLength[selectedValue] - 15) * 60 * 1000) < endTime) {
                                        currentTimeSlots.push(new Date(currentTime));
                                        currentTime = new Date(currentTime.getTime() + 15 * 60 * 1000); // Add 15 minutes
                                    }
                                }

                                // Update the current date
                                currentDate.setTime(startTime.getTime())
                            })

                            // Final iteration
                            if (!timeSlotsJSX) {
                                if (currentTimeSlots.length > 0) {
                                    currentFirstTime = currentTimeSlots[0]
                                    timeSlotsJSX = (
                                        <div className="form-group full-width">
                                            <label htmlFor="bookingSelect">Select Time</label>
                                            <div className="booking-select">
                                                <select className="form-select" value={selectedTime} onChange={(e) => setSelectedTime(e.target.value)}>
                                                    {currentTimeSlots.map((timeSlot, index) => (
                                                        <option key={timeSlot} value={timeSlot}>{`${timeSlot.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }).toLowerCase()}`}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    );

                                    currentTimeSlots = []
                                } else {
                                    timeSlotsJSX = (
                                        <div className="no-available">
                                            <p>No available times</p>
                                        </div>
                                    )
                                }
                            }

                            firstTime.current = currentFirstTime
                            return timeSlotsJSX
                        })()}
                    </div>
                </div>
            )}
            <div className="booking-error">
                {dateError}
            </div>
            <div className="form-group half-width-container">
                <div className="half-width-left">
                    <label htmlFor="fname">First Name</label>
                    <input type="text" id="fname" value={bookingFirstName} onChange={(e) => setBookingFirstName(e.target.value)}/>
                </div>
                <div className="half-width-right">
                    <label htmlFor="lname">Last Name</label>
                    <input type="text" id="lname" value={bookingLastName} onChange={(e) => setBookingLastName(e.target.value)}/>
                </div>
            </div>
            <div className="form-group half-width-container">
                <div className="booking-error left-booking-error">
                    {firstNameError}
                </div>
                <div className="booking-error right-booking-error">
                    {lastNameError}
                </div>
            </div>
            <div className="form-group full-width">
                <label htmlFor="email">Email Address</label>
                <input type="email" id="email" name="email" value={bookingEmailAddress} onChange={(e) => setBookingEmailAddress(e.target.value)}/>
            </div>
            <div className="booking-error">
                {emailError}
            </div>
            <div className="form-group full-width">
                <label htmlFor="phone">Phone Number</label>
                <div className="input-container">
                    <span className="phone-number-nz">+64</span>
                    <input id="phone" name="phone" value={bookingPhoneNumber} onChange={handlePhoneNumberChange} />
                </div>
            </div>
            <div className="booking-error">
                {phoneError}
            </div>
            <div className="form-group full-width">
                <label htmlFor="comments">How can I help you? <span className="optional-text">(optional)</span></label>
                <textarea id="comments" name="comments" rows="4" value={bookingComments} onChange={(e) => setBookingComments(e.target.value)}/>
            </div>
            <div id="submitBooking">
                <button className="booking-submit" type="submit">Book</button>
            </div>
        </form>
    );
}

export default Booking